import React from 'react';
import { GradientBtn } from 'inlineStyles/common';
import {
  AiOutlineSync,
  AiOutlineImport,
  AiOutlineCloudDownload,
  AiOutlineCloudUpload,
} from 'react-icons/ai/index.esm';
import { VscAdd, VscTrash } from 'react-icons/vsc/index.esm';
import { FaRegPaperPlane, FaEdit } from 'react-icons/fa/index.esm';
import { FiCheckSquare, FiSquare } from 'react-icons/fi/index.esm';
import { MdCancel } from 'react-icons/md/index.esm';
import { BsBoxArrowRight } from 'react-icons/bs/index.esm';

export const BtnImport = ({
  label = 'Import',
  width = '9rem',
  iconComponent = <AiOutlineImport className="su-icon mr-2 text-white" />,
  ...props
}) => (
  <GradientBtn
    height="2.25rem"
    width={width}
    fontSize="0.85rem"
    type="button"
    // className="mr-3"
    {...props}
  >
    <span className="flex-centercenter">
      {iconComponent}
      {label}
    </span>
  </GradientBtn>
);
export const BtnDownload = ({
  label = 'Download',
  width = '9rem',
  iconComponent = <AiOutlineCloudDownload className="su-icon mr-2 text-white" />,
  ...props
}) => (
  <GradientBtn
    height="2.25rem"
    width={width}
    fontSize="0.85rem"
    type="button"
    // className="mr-3"
    strColor="#21dfcb"
    {...props}
  >
    <span className="flex-centercenter">
      {iconComponent}
      {label}
    </span>
  </GradientBtn>
);
export const BtnUpload = ({
  label = 'Upload',
  width = '9rem',
  iconComponent = <AiOutlineCloudUpload className="su-icon mr-2 text-white" />,
  ...props
}) => (
  <GradientBtn
    height="2.25rem"
    width={width}
    fontSize="0.85rem"
    type="button"
    // className="mr-3"
    {...props}
  >
    <span className="flex-centercenter">
      {iconComponent}
      {label}
    </span>
  </GradientBtn>
);
export const BtnSync = ({
  label = '最新情報取得',
  width = '9rem',
  iconComponent = <AiOutlineSync className="su-icon mr-2 text-white" />,
  ...props
}) => (
  <GradientBtn
    height="2.25rem"
    width={width}
    fontSize="0.85rem"
    type="button"
    // className="mr-3"
    {...props}
  >
    <span className="flex-centercenter">
      {iconComponent}
      {label}
    </span>
  </GradientBtn>
);
export const BtnSubmit = ({
  label = '実行',
  width = '9rem',
  iconComponent = <FaRegPaperPlane className="su-icon mr-2 text-white" />,
  ...props
}) => (
  <GradientBtn
    height="2.25rem"
    width={width}
    fontSize="0.85rem"
    type="button"
    // className="mr-3"
    {...props}
  >
    <span className="flex-centercenter">
      {iconComponent}
      {label}
    </span>
  </GradientBtn>
);
export const BtnUpdate = ({
  label = '変更',
  width = '9rem',
  iconComponent = <FaEdit className="su-icon mr-2 text-white" />,
  ...props
}) => (
  <GradientBtn
    height="2.25rem"
    width={width}
    fontSize="0.85rem"
    type="button"
    // className="mr-3"
    strColor="#47C1BF"
    endColor="#47C1BF"
    {...props}
  >
    <span className="flex-centercenter">
      {iconComponent}
      {label}
    </span>
  </GradientBtn>
);
export const BtnAppend = ({
  label = '追加',
  width = '9rem',
  iconComponent = <VscAdd className="su-icon mr-2 text-white" />,
  ...props
}) => (
  <GradientBtn
    height="2.25rem"
    width={width}
    fontSize="0.85rem"
    type="button"
    // className="mr-3"
    strColor="#37e2d0"
    {...props}
  >
    <span className="flex-centercenter">
      {iconComponent}
      {label}
    </span>
  </GradientBtn>
);
export const BtnDelete = ({
  label = '削除',
  width = '9rem',
  iconComponent = <VscTrash className="su-icon mr-2 text-white" />,
  ...props
}) => (
  <GradientBtn
    height="2.25rem"
    width={width}
    fontSize="0.85rem"
    type="button"
    // className="mr-3"
    strColor="#fe9ecb"
    {...props}
  >
    <span className="flex-centercenter">
      {iconComponent}
      {label}
    </span>
  </GradientBtn>
);
export const BtnCancel = ({
  label = 'cancel',
  width = '9rem',
  iconComponent = <MdCancel className="su-icon mr-2 text-white" />,
  ...props
}) => (
  <GradientBtn
    height="2.25rem"
    width={width}
    fontSize="0.85rem"
    type="button"
    // className="mr-3"
    strColor="#C4CDD5"
    endColor="#919EAB"
    {...props}
  >
    <span className="flex-centercenter">
      {iconComponent}
      {label}
    </span>
  </GradientBtn>
);
export const BtnMove = ({
  label = '移動',
  width = '9rem',
  iconComponent = <BsBoxArrowRight className="su-icon mr-2 text-white" />,
  ...props
}) => (
  <GradientBtn
    height="2.25rem"
    width={width}
    fontSize="0.85rem"
    type="button"
    // className="mr-3"
    strColor="#C4CDD5"
    endColor="#919EAB"
    {...props}
  >
    <span className="flex-centercenter">
      {iconComponent}
      {label}
    </span>
  </GradientBtn>
);

export const BtnCheck = ({ label = '', checked = true, width = '9rem', ...props }) => (
  <GradientBtn
    height="2.25rem"
    width={width}
    fontSize="0.85rem"
    type="button"
    // className="mr-3"
    {...props}
  >
    {checked ? (
      <FiCheckSquare className="su-icon mr-2 text-white" />
    ) : (
      <FiSquare className="su-icon mr-2 text-white" />
    )}
    {label}
  </GradientBtn>
);
