/**
 * Loginが必要なRoute
 * piao 2022-0929
 */
import { Outlet, Navigate } from 'react-router-dom';
import useIsLogin from 'hooks/useIsLogin';

const ProtectedRoute = () => {
  const isLogin = useIsLogin();
  if (isLogin) {
    return <Outlet />;
  } else {
    return <Navigate to="/sign/login" />;
  }
};
export default ProtectedRoute;
