/**
 * 共通Style
 *
 *  piao 2021-03-28
 */

import styled, { css } from 'styled-components';
import colors from './colors';

export const CursorIcon = styled.div`
  cursor: pointer;
  color: ${({ color }) => color || colors.indigo};
  padding-top: 2px;
  padding: ${({ padding }) => padding && padding};
  margin: ${({ margin }) => margin && margin};

  ${(props) =>
    props.disabled &&
    css`
      color: ${colors.sky};
      cursor: default;
    `}
  &:hover:not([disabled]) {
    color: ${colors.teal};
  }
`;

export const CycleHover = styled.div`
  z-index: 2000;
  cursor: pointer;
  padding: ${({ padding }) => padding || '5px 6px'};
  border-radius: 50%;
  color: ${({ color }) => color || colors['indigo-dark']};
  transition: all 0.5s;
  background-color: transparent;
  opacity: 0.25;

  &:hover:not([disabled]) {
    box-shadow: 0 16px 60px 8px rgba(90, 116, 148, 0.4);
    color: ${colors['indigo']};
    font-weight: 500;
    opacity: 0.95;
  }
`;
export const CycleIcon = styled.div`
  z-index: 2000;
  cursor: pointer;
  padding: ${({ padding }) => padding || '5px 6px'};
  border-radius: 50%;
  color: ${colors['indigo-dark']};
  transition: all 0.5s;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};

  &:hover:not([disabled]) {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
    color: ${colors['indigo']};
    font-weight: 500;
  }
`;
export const RoundB = styled.b`
  z-index: 2000;
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  color: ${colors['indigo-dark']};
  transition: all 0.5s;
  // background-color: transparent;
  background-color: white;
  position: absolute;
  right: 0.75rem;

  &:hover:not([disabled]) {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
    color: ${colors['indigo']};
    font-weight: 500;
  }
`;

export const GradientBtn = styled.button`
  cursor: pointer;
  display: inline-block;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '36px'};
  line-height: ${({ height }) => height || '36px'};
  border: none;
  padding: 0;
  background: ${({ degree, strColor, endColor, disabled }) =>
    `linear-gradient(${degree || '135deg'}, ${
      disabled ? '#868e9685' : strColor || '#4a90e2'
    } 0, ${disabled ? '#868e9685' : endColor || '#8ebbf7'} 100%)`};
  border-radius: ${({ borderRadius }) => borderRadius || '2px'};
  color: ${({ foreColor }) => foreColor || '#fff'};
  font-size: ${({ fontSize }) => fontSize || '14px'};
  box-shadow: ${({ boxShadow }) =>
    boxShadow || '0 10px 20px 0 rgba(0, 0, 0, 0.13)'};
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;

  &:hover {
    box-shadow: 0 3px 5px 2px rgba(84, 125, 213, .3);
  },
`;
export const GradientHeader = styled.div`
  display: inline-block;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '36px'};
  line-height: ${({ height }) => height || '36px'};
  border: 0;
  padding: 0;
  background: ${({ degree, strColor, endColor }) =>
    `linear-gradient(${degree || '135deg'}, ${strColor || '#4a90e2'} 0, ${
      endColor || '#8ebbf7'
    } 100%)`};
  border-radius: ${({ brTL, brTR, brBR, brBL }) =>
    `${brTL || '2px'} ${brTR || '2px'} ${brBR || '0'} ${brBL || '0'}`};
  color: #fff;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  box-shadow: ${({ boxShadow }) =>
    boxShadow || '0 2px 6px 0 rgba(0, 0, 0, 0.13)'};
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
`;
export const Gradient = styled.div`
  display: inline-block;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '100%'};
  line-height: ${({ height }) => height || '100%'};
  background: ${({ degree, strColor, endColor }) =>
    `linear-gradient(${degree || '135deg'}, ${strColor || '#4a90e2'} 0, ${
      endColor || '#8ebbf7'
    } 100%)`};
  border-radius: ${({ brTL, brTR, brBR, brBL }) =>
    `${brTL || '2px'} ${brTR || '2px'} ${brBR || '0'} ${brBL || '0'}`};
  color: #fff;
  box-shadow: ${({ boxShadow }) =>
    boxShadow || '0 2px 6px 0 rgba(0, 0, 0, 0.13)'};
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
`;

// share-mgmtのedit/compact-toolbarがuser/dropdownで隠されてしまう。20211210
const dropdownZindex = 100;

export const Dropdown = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${({ top }) => top || '8px'};
  right: ${({ right }) => right || '10px'};
  z-index: ${dropdownZindex};
  padding: 0;
  color: ${colors['indigo-dark']};

  &:hover:not([disabled]) {
    color: ${colors.indigo};
  }
`;
export const DropdownMenu = styled.div`
  z-index: ${dropdownZindex};
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
  position: absolute;
  top: ${({ top }) => top && top};
  left: ${({ left }) => left && left};
  right: ${({ right }) => right && right};
  bottom: ${({ bottom }) => bottom && bottom};
  width: ${({ width }) => width && width};
  min-width: ${({ width }) => width && width};
  min-height: ${({ minHeight }) => minHeight && minHeight};
  background: white;
`;
export const DropdownItem = styled.div`
  margin: 0;
  padding: ${({ padding }) => padding || '0.5rem 0.75rem'};
  border-bottom: 1px solid ${colors['purple-lighter']};
  color: ${({ disabled }) => (disabled ? colors['gray-500'] : colors.indigo)};
  // background-color: ${({ disabled }) => disabled && colors['gray-300']};
  user-select: none;
  width: ${({ width }) => width || '100%'};
`;

export const IconStack = styled.span`
  display: grid;
  svg {
    grid-area: 1 / 1;
  }
`;

// 3001->2050：modalと同じく設定。modalの上に表示されるため。
export const FloatBtn = styled.div`
  z-index: ${({ zIndex }) => zIndex || 2050};
  cursor: pointer;
  position: ${({ position }) => position || 'fixed'};
  right: ${({ right }) => right && right};
  bottom: ${({ bottom }) => bottom && bottom};
  top: ${({ top }) => top && top};
  left: ${({ left }) => left && left};
  background-color: ${({ bgColor }) => bgColor || colors.indigo};
  color: ${({ color }) => color || 'white'};
  border-radius: ${({ borderRadius }) => borderRadius || '50%'};
  padding: ${({ padding }) => padding || '0.5rem'};
  box-shadow: ${({ boxShadow }) => boxShadow && '0 0 16px 4px #dee2e6'};
  opacity: ${({ hover }) => hover && '0.35'};
  transition: all 300ms ease-in-out;

  ${({ hover }) =>
    hover &&
    `
    &:hover:not([disabled]) {
      box-shadow: 0 3px 5px 2px rgba(84, 125, 213, .3);
      color: ${colors['indigo']};
      font-weight: 500;
      opacity: 0.95;
    }`}
`;

export const HeaderBtn = styled.div`
  cursor: pointer;

  border: 1px solid ${colors.sky};
  border-radius: 0.25rem;
  padding: ${({ padding }) => padding || '0.25rem 0.75rem'};

  &:hover {
    background: #b3bcf533;
  }
`;

export const ImageBackground = styled.div`
  background-image: ${({ src }) => src && `url("${src}")`};
  background-size: cover;
  height: ${({ height }) => height || '12.5rem'};
  cursor: pointer;
`;

export const FormButton = styled('button')({
  margin: '15px auto',
  display: 'block',
  background: 'linear-gradient(45deg, #63C8F7 2%, #547DD5 100%)',
  border: '1px solid #efefef',
  borderRadius: '30px',
  boxShadow: '0 3px 5px 2px rgba(84, 125, 213, .1)',
  fontFamily: 'nunito, sans-serif',
  fontWeight: '400',
  fontSize: '1rem',
  color: 'white',
  height: 36,
  width: '100%',
  padding: '0 30px',
  transition: 'background 200ms ease-in-out',
  '&:hover': {
    background: '#547DD5',
    boxShadow: '0 3px 5px 2px rgba(84, 125, 213, .3)',
  },
});

export const Button = styled.button`
  cursor: pointer;
  display: ${({ display }) => display || 'block'};
  margin: ${({ margin }) => margin || '0.5rem 0'};
  padding: ${({ padding }) => padding || '0 6px'};
  background: ${({ background }) =>
    background || 'linear-gradient(45deg, #63C8F7 2%, #547DD5 100%)'};
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius || '8px'};
  box-shadow: ${({ boxShadow }) =>
    boxShadow || '0 3px 36px 16px rgba(84, 125, 213, 0.1)'};
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  color: ${({ color }) => color || 'white'};
  height: ${({ height }) => height || '36px'};
  width: ${({ width }) => width || '100%'};
  transition: all 200ms ease-in-out;
  position: ${({ position }) => position || 'relative'};
  right: ${({ right }) => right && right};
  bottom: ${({ bottom }) => bottom && bottom};

  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        box-shadow: 0 3px 36px 16px rgba(84, 125, 213, 0.16);
        transform: scale(1.1);
      }
    `};
`;
