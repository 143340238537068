import axios from 'axios';
import localForage from 'localforage';
import { Swal } from 'components/Notifications';
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';
// api
import endpoint from 'config/endpoint';
import customNanoid from 'utils/customNanoid';

// axios.defaults.withCredentials = true;//default false
const authAxios = axios.create({
  baseURL: `${endpoint.api_endpoint}/`,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': true,
  },
});

// https://github.com/axios/axios/issues/815
authAxios.interceptors.response.use(
  (response) => {
    nprogress.done();
    return response;
  },
  (error) => {
    nprogress.done();
    // 401は権限チェックに限定する。piao 2019-11-07
    if (_.get(error, ['response', 'status']) === 401) {
      const message = error.response?.data?.message;
      const remarks = error.response?.data?.remarks;
      Swal.fire({
        title: message,
        icon: 'warning',
        html: `<h5>${remarks || '処理を継続される場合、｢ホームに戻って｣ログインして下さい。'}</h5>`,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ホームに戻る',
        cancelButtonText: 'cancel',
      }).then((result) => {
        if (result.value) {
          // login状態をクリアしないと、再ログインが出来ないため。2020-10-08
          localForage.setItem('login', 'NG');
          window.location.href = '/';
        }
      });
    }
    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error));
        };
        reader.onerror = () => {
          reject(error);
        };
        reader.readAsText(error.response.data);
      });
    }
    return Promise.reject(error);
  },
);

authAxios.interceptors.request.use(async (request) => {
  // console.log('interceptor-request:', request);
  nprogress.start();
  const token = (await localForage.getItem('token')) || '';
  let _id_ = (await localForage.getItem('_id_')) || '';
  request.headers.Authorization = `Bearer ${token}`;
  // clientを特定する為に、localForageに_id_をセット&axiosのheaderにセット。2021-1229
  if (!_id_) {
    _id_ = customNanoid();
    localForage.setItem('_id_', _id_);
  }
  request.headers.Identifyclient = `${_id_}`;
  // request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  // request.headers['content-type'] = 'multipart/form-data';
  return request;
});

export default authAxios;
