/*
 * AppConstants:for maintenance piao 2019-11-08
 */
// saga - server &&
export const SET_CONTAINER = 'shareup/App/SET_CONTAINER';
export const SET_CONTAINER_SUCCESS = 'shareup/App/SET_CONTAINER_SUCCESS';
export const DEL_CONTAINER_SUCCESS = 'shareup/App/DEL_CONTAINER_SUCCESS';
export const SET_CONTAINER_ERROR = 'shareup/App/SET_CONTAINER_ERROR';
export const GET_CONTAINER = 'shareup/App/GET_CONTAINER';
export const GET_CONTAINER_SUCCESS = 'shareup/App/GET_CONTAINER_SUCCESS';
export const GET_CONTAINER_ERROR = 'shareup/App/GET_CONTAINER_ERROR';
