/**
 * 独立なpanel実装
 * piao 2022-02-07
 * react-portalでbody配下に作成
 * inPortalを追加、=falseの場合、childrenをそのまま返す。WithContentClass
 * piao 2022-0316
 * Panel内でscrollする場合、以下の様に指定
 * contentClass="p-3 custom-scrollbar h-full"
 * piao 2022-0408
 */
import React, { forwardRef, useState } from 'react';
import { PortalWithState } from 'react-portal';
import PropTypes from 'prop-types';

import { AiOutlineClose } from 'react-icons/ai/index.esm';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs/index.esm';
import { useCss } from 'react-use';
import { collapseHeaderDEF } from 'config/constants';

const PortalPanel = forwardRef(
  (
    {
      header,
      headerClass,
      headerSize = 'md',
      positionIcon,
      contentClass = 'p-3',
      positionClass = 'top-1 left-1',
      width = '50vw',
      height = '100vh',
      closeOnOutsideClick = false,
      closeOnEsc = false,
      defaultOpen = true,
      inPortal = true,
      showExpand = false,
      initExpand = false,
      wrapperClass,
      onOpen,
      onClose,
      children,
      ...props
    },
    ref,
  ) => {
    const [expand, setExpand] = useState(initExpand);

    const defaultWrapperClass = useCss({
      width,
      height,
    });
    const headerSizeClass = useCss({
      padding: collapseHeaderDEF[headerSize].padding,
    });
    const iconPositionClass = useCss({
      top: collapseHeaderDEF[positionIcon || headerSize].top,
      right: collapseHeaderDEF[positionIcon || headerSize].right,
    });
    const iconPositionTopClass = useCss({
      top: collapseHeaderDEF[positionIcon || headerSize].top,
    });

    return inPortal ? (
      <PortalWithState
        closeOnOutsideClick={closeOnOutsideClick}
        closeOnEsc={closeOnEsc}
        defaultOpen={defaultOpen}
        onOpen={() => onOpen && onOpen()}
        onClose={() => onClose && onClose()}
      >
        {({ closePortal, portal }) => (
          <>
            {portal(
              <div
                className={clsx(
                  'fixed z-50 bg-white',
                  positionClass,
                  defaultWrapperClass,
                  wrapperClass,
                  { 'min-vw-100 min-vh-100': showExpand && expand },
                )}
                {...props}
                ref={ref}
              >
                <div
                  className={clsx(
                    'relative flex flex-row',
                    { [headerSizeClass]: header },
                    headerClass,
                  )}
                >
                  <div
                    className={clsx('m-0 w-full text-slate-300', {
                      hidden: !header,
                    })}
                  >
                    {header || ''}
                  </div>
                  {showExpand &&
                    (expand ? (
                      <BsFullscreenExit
                        className={clsx(
                          'tw-icon z-9999 absolute right-7 cursor-pointer',
                          iconPositionTopClass,
                        )}
                        onClick={() => setExpand(false)}
                        title="戻る"
                      />
                    ) : (
                      <BsFullscreen
                        className={clsx(
                          'tw-icon z-9999 absolute right-7 cursor-pointer',
                          iconPositionTopClass,
                        )}
                        onClick={() => setExpand(true)}
                        title="最大化"
                      />
                    ))}
                  <span
                    className={clsx('z-9999 absolute cursor-pointer', iconPositionClass)}
                    onClick={() => {
                      closePortal();
                    }}
                    title="close"
                  >
                    <AiOutlineClose className="tw-icon" />
                  </span>
                </div>
                <div className={contentClass}>{children}</div>
              </div>,
            )}
          </>
        )}
      </PortalWithState>
    ) : (
      children
    );
  },
);

PortalPanel.propTypes = {
  header: PropTypes.node,
  headerSize: PropTypes.string,
  contentClass: PropTypes.string,
  headerClass: PropTypes.string,
  positionClass: PropTypes.string,
  positionIcon: PropTypes.string,
  props: PropTypes.object,
  children: PropTypes.node,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  closeOnOutsideClick: PropTypes.bool,
  closeOnEsc: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  inPortal: PropTypes.bool,
  showExpand: PropTypes.bool,
  initExpand: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  wrapperClass: PropTypes.string,
};

export default PortalPanel;
