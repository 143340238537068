/**
 * 各種共通処理関数（メソッド）を定義する。
 * piao 2022-02-23
 */

export const arr2csv = (array) =>
  _.map(array, (mbr) => {
    if (_.isArray(mbr)) return `"${mbr.join('","')}"`;
    if (_.isPlainObject(mbr)) return `"${_.values(mbr).join('","')}"`;
    return mbr;
  }).join('\r\n');

export const isTrue = (value) => value === true || value === 'true';
export const isBool = (value) => _.includes([true, false, 'true', 'false'], value);
//a,bが同時boolで同じのみtrue.
export const isBoolEq = (a, b) => isBool(a) && isBool(b) && isTrue(a) === isTrue(b);
export const isNumberEq = (a, b) => _.isNumber(a) && _.isNumber(b) && Number(a) === Number(b);

export const hasOneFalse = (value) => _.size(_.compact(value)) !== _.size(value);
export const hasTrue = (value) => _.size(_.compact(value)) > 0;
export const allTrue = (value) => _.size(_.compact(value)) === _.size(value);
export const evl = (value, defValue) => (_.isEmpty(value) ? defValue : value);
