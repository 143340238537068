export const cb_success = (message, onFinish) => {
  Toast.fire({
    icon: 'success',
    text: message,
  });
  onFinish && onFinish();
};
export const cb_failed = (message, onFinish) => {
  Toast.fire({
    icon: 'warning',
    text: message,
  });
  onFinish && onFinish();
};

export const cb_info = (message, info, onFinish) => {
  Toast.fire({
    icon: 'info',
    text: message || JSON.stringify(info, null, 2),
  });
  onFinish && onFinish();
};
