/**
 * instanceを取得するために、forwardRef
 * piao 2022-1121
 */
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';

const OverlayWrapper = forwardRef(
  (
    {
      classNames,
      theme = 'os-theme-gradient-slate',
      // theme = 'os-theme-deep-blue',
      autoHide = 'leave', // after 'never', 'scroll', 'leave' and 'move'.
      overflow = { x: 'hidden' },
      visibility = 'auto',
      children,
    },
    ref,
  ) => {
    return (
      <OverlayScrollbarsComponent
        element="span"
        options={{
          scrollbars: {
            theme,
            autoHide,
            visibility,
            autoHideDelay: 1300,
            dragScroll: true,
            clickScroll: true,
            pointers: ['mouse', 'touch', 'pen'],
          },
          overflow,
        }}
        className={classNames}
        ref={ref}
      >
        {children}
      </OverlayScrollbarsComponent>
    );
  },
);

OverlayWrapper.propTypes = {
  classNames: PropTypes.string,
  theme: PropTypes.string,
  autoHide: PropTypes.string,
  theme: PropTypes.string,
  overflow: PropTypes.object,
  children: PropTypes.node,
  visibility: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default OverlayWrapper;
