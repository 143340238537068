/**
 * 例外ハンドリング
 * 1.apps/router/indexの内部から分離して共通化
 * piao 2022-0430
 */

import React, { useMemo} from 'react';
import {
  useNavigate
} from 'react-router-dom';
import { GradientBtn } from 'inlineStyles/common';
import {nanoid} from 'nanoid';
import {VscFeedback,VscHome,VscStarEmpty} from 'react-icons/vsc/index.esm'
import {BtnSync} from 'components/GradientBtn';
import useApi from 'hooks/useApi';
import useAction from 'hooks/useAction';

function ErrorFallback({error}) {
  const navigate = useNavigate();
  const {message,stack} = error;
  const api = useApi();
  const action = useAction();

  const isChunkFailed = useMemo(
    ()=>_.startsWith(message,'Loading chunk'),
    [message]
  );

  const handleFeedback = async () =>{
    const user = action.getUser();
    const pattern = `feedback:fromuser:${user?.mu_uuid}`;
    const key = `${pattern}:${nanoid()}:${dayjs().format(FORMAT_TIMESTR)}`;
    const feedbackMessage = {
      createdAt:dayjs().format(FORMAT_DAYTIME),
      user:_.pick(user,['mu_uuid','mu_email','mu_publicname','mu_type','mu_authority']),
      message,
      stack
    }
    await api.setRedisInfo({
      data:{
        key,
        value:JSON.stringify(feedbackMessage),
        expires:3600 * 24 * 365
      },
    });
  }

  return (
    <main role="main" className="page-content  p-1 p-md-2 p-lg-3 p-xl-4">
      {isChunkFailed ? (
        <>
          <div className="alert border-danger bg-transparent text-danger">
            プログラムが古いです。画面を再ロードして、プログラムを更新して下さい。
          </div>
          <BtnSync
            onClick={()=>navigate(-1)}
            width="15rem"
            height="2.25rem"
            label="再ロードしてバージョンアップ"
          />
        </>
      ):(
        <div className="alert alert-danger flex flex-col">
          <p>予期していないエラーが発生しています。</p>
          <p>詳細を確認し、対処して下さい。</p>
          <p className="text-indigo-600 font-medium"><VscStarEmpty className="svg-5 mr-2"/>「フィードバック」ボタンをクリックすると、エラー情報がサポート側に連携されます。サポート側からこの情報に基づいて、適切に対処致します。</p>
          <p className="text-indigo-600 font-medium"><VscStarEmpty className="svg-5 mr-2"/>「ホームに戻る」ボタンをクリックして、最初からやり直して下さい。</p>
          <p>ご不便をかけして申し訳ございません。</p>
        </div>
      )}
      <pre>{error.message}</pre>
      <div className="flex flex-row justify-start items-center">
        <GradientBtn
          height="2.25rem"
          width="15rem"
          fontSize="0.85rem"
          strColor={"#0c7cd5"}
          endColor={"#0c7cd5"}
          degree="90deg"
          onClick={()=>{
            navigate('/portal/home');
            navigate(-1);
          }}
          className="mr-3"
          type="button"
        >
          <VscHome className="svg-6 text-white mr-3"/>
          ホームに戻る
        </GradientBtn>
        <GradientBtn
          height="2.25rem"
          width="15rem"
          fontSize="0.85rem"
          strColor={"#4852ff"}
          endColor={"#55ceff"}
          degree="90deg"
          onClick={()=>handleFeedback(error)}
          type="button"
        >
          <VscFeedback className="svg-6 text-white mr-3"/>
          フィードバック
        </GradientBtn>
      </div>
    </main>
  );
}

export default ErrorFallback;
