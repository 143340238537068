import { configureStore } from '@reduxjs/toolkit';
import { storeReducer } from 'apps/router/storeReducer';
import { contentReducer } from 'apps/router/contentReducer';
import { containerReducer } from 'apps/router/containerReducer';
export default configureStore({
  reducer: {
    content: contentReducer,
    contentainer: containerReducer,
    store: storeReducer,
  },
});
