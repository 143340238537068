/**
 * Collapse機能を再設計
 * 1.headerをBodyと分離させる。
 * 2.class/header,content2つに集約
 * 3.hrを取り除く。必要ならば、contentで自分で追加
 * 4.headerSize:sm/md/lg
 * piao 2022-01-13
 * @kunukn/react-collapseでCollapseの基本機能を実現する。
 * 1.collapseOptionsはLibraryへのOption
 * 2.forwardRefを使って、親にrefを渡す。dnd用
 * piao 20220121
 * 1.onOpenClassSetsはopen状態の場合、指定するclassを適用する
 * wrapperClass:headerClass:contentClass
 * piao 2022-0223
 * T/W対応
 * piao 2022-0920
 */

import React, { forwardRef, useEffect, useMemo } from 'react';
import Collapse from '@kunukn/react-collapse';
import PropTypes from 'prop-types';
import PortalPanel from 'components/PortalPanel';

import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai/index.esm';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md/index.esm';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs/index.esm';
import { useToggle, useCss } from 'react-use';
import { collapseHeaderDEF } from 'config/constants';

const MyComponent = forwardRef(
  (
    {
      header = '',
      initOpen = false,
      headerClass,
      headerSize = 'md',
      contentClass = 'p-3',
      handleClick,
      children,
      collapsable = true,
      hoverable = true,
      useOutside = false,
      arrowIcon = false,
      showCorner = false,
      cornerClass = '--xs',
      corner,
      fullscreenable = false,
      onInit,
      onChange,
      onFullscreen,
      onOpenClassSets = {},
      collapseOptions = {},
      ...props
    },
    ref,
  ) => {
    const [show, setShow] = useToggle(initOpen);
    const [isFullscreen, setIsFullscreen] = useToggle(false);

    // {...props}を直接使うと、他のclassNameを上書きするため、その分を抜き出して、個別適用する。2022-0407
    const { className: inheritedClass = '', ...remainProps } = props;

    const wrapperClass = 'hover:shadow-lg shadow-indigo-100';
    const headerSizeClass = useCss({
      padding: collapseHeaderDEF[headerSize].padding,
    });
    const fullPositionClass = useCss({
      top: collapseHeaderDEF[headerSize].top,
      right: `calc(${collapseHeaderDEF[headerSize].right} + 2.0rem)`,
    });

    // console.log('Collapse', show, initOpen);
    const openIcon = useMemo(
      () =>
        arrowIcon ? (
          <MdKeyboardArrowDown className="svg-4" />
        ) : (
          <AiOutlineMinus className="svg-4" />
        ),
      [arrowIcon],
    );
    const closeIcon = useMemo(
      () =>
        arrowIcon ? (
          <MdKeyboardArrowRight className="svg-4" />
        ) : (
          <AiOutlinePlus className="svg-4" />
        ),
      [arrowIcon],
    );

    useEffect(() => {
      setShow(initOpen);
    }, [initOpen]);

    const CollapseComponent = useMemo(
      () => (
        <div
          className={clsx(
            'flex flex-col',
            hoverable && wrapperClass,
            { [onOpenClassSets.wrapperClass || '']: show },
            inheritedClass,
          )}
          {...remainProps}
        >
          <div
            className={clsx(
              'relative flex flex-row',
              headerSizeClass,
              headerClass,
              { [onOpenClassSets.headerClass || '']: show },
              { 'corner-container': showCorner },
            )}
          >
            <div
              className={clsx('m-0 w-full text-slate-400', {
                'cursor-pointer': collapsable,
              })}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (!collapsable) return;
                if (useOutside) {
                  handleClick && handleClick();
                } else {
                  const _show = !show;
                  setShow(_show);
                  handleClick && handleClick(_show);
                }
              }}
            >
              {showCorner && (
                <div className={clsx('corner-right-top', cornerClass)}>{corner && corner}</div>
              )}
              {header}
            </div>
            <span
              className={clsx('absolute', fullPositionClass, {
                hidden: !fullscreenable,
              })}
            >
              {isFullscreen ? (
                <BsFullscreenExit
                  className="tw-icon cursor-pointer"
                  title="全画面モード終了"
                  onClick={() => {
                    setIsFullscreen(false);
                    onFullscreen && onFullscreen(false);
                  }}
                />
              ) : (
                <BsFullscreen
                  className="tw-icon cursor-pointer"
                  title="全画面モード表示"
                  onClick={() => {
                    setIsFullscreen(true);
                    onFullscreen && onFullscreen(true);
                  }}
                />
              )}
            </span>
            <span
              className={clsx('ml-auto self-center text-slate-500', {
                hidden: !collapsable,
              })}
            >
              {show ? openIcon : closeIcon}
            </span>
          </div>
          <Collapse
            isOpen={show || !collapsable}
            onChange={onChange}
            onInit={onInit}
            {...collapseOptions}
          >
            <div
              className={clsx(contentClass, {
                [onOpenClassSets.contentClass || '']: show,
              })}
            >
              {children}
            </div>
          </Collapse>
        </div>
      ),
      [
        show,
        header,
        headerClass,
        headerSize,
        contentClass,
        handleClick,
        children,
        collapsable,
        hoverable,
        onOpenClassSets,
        isFullscreen,
      ],
    );

    return (
      <PortalPanel
        inPortal={isFullscreen}
        onClose={() => {
          setIsFullscreen(false);
          onFullscreen && onFullscreen(false);
        }}
        headerClass="border-b-1 border-slate-200 bg-white"
        positionClass="top-1 left-1"
        height="calc(100vh - 1rem)"
        width="calc(100vw - 1rem)"
        contentClass="p-0"
        positionIcon="corner"
        closeOnEsc={true}
      >
        <div ref={ref}>{CollapseComponent}</div>
      </PortalPanel>
    );
  },
);

MyComponent.propTypes = {
  header: PropTypes.node,
  corner: PropTypes.node,
  initOpen: PropTypes.bool,
  headerClass: PropTypes.string,
  headerSize: PropTypes.string,
  contentClass: PropTypes.string,
  onOpenClassSets: PropTypes.object,
  handleClick: PropTypes.func,
  onInit: PropTypes.func,
  onChange: PropTypes.func,
  onFullscreen: PropTypes.func,
  collapsable: PropTypes.bool,
  hoverable: PropTypes.bool,
  useOutside: PropTypes.bool,
  arrowIcon: PropTypes.bool,
  showCorner: PropTypes.bool,
  cornerClass: PropTypes.string,
  className: PropTypes.string,
  fullscreenable: PropTypes.bool,
  collapseOptions: PropTypes.object,
  children: PropTypes.node,
};

export default MyComponent;
