/**
 * login状態をglobal.userから取得する
 * piao 2021-0301
 */
import { useUsers } from 'hooks/useZustand';

export default function useIsLogin() {
  const [user] = useUsers((state) => [state.user]);
  return user?.isLogging || false;
}
