/**
 * 接続先管理
 * 1.shareup.jp
 * piao 2023-02-23
 */
const ProductionMode = {
  s3_endpoint: 'https://s3.ap-northeast-1.wasabisys.com',
  s3_cdn_endpoint: 'https://shareup.s.llnwi.net',
  api_endpoint: 'https://api.shareup.jp',
  socket_endpoint: 'https://api.shareup.jp',
  socket_endpoint_chat: 'https://api.shareup.jp/socket-chat',
  socket_endpoint_notification: 'https://api.shareup.jp/notification',
  base_url: 'https://www.shareup.jp',
  apps_url: 'https://apps.shareup.jp',
  appslp_url: 'https://apps.shareup.jp',
  docs_url: 'https://docs.shareup.jp',
  board_url: 'https://board.shareup.jp',
  story_url: 'https://story.shareup.jp',
  help_url: 'https://help.shareup.jp/docs',
  folder_url: 'https://folder.shareup.jp',
  blog_url: 'https://blog.shareup.jp',
  jobs_url: 'https://jobs.shareup.jp',
  discuss_url: 'https://discuss.shareup.jp',
  booking_url: 'https://booking.shareup.jp',
  travel_url: 'https://travel.shareup.jp',
  cookie_domain: '.shareup.jp',
};
const DevelopmentMode = {
  s3_endpoint: 'https://s3.ap-northeast-1.wasabisys.com',
  s3_cdn_endpoint: 'https://shareup.s.llnwi.net',
  api_endpoint: 'http://localhost:8002',
  socket_endpoint: 'http://localhost:8002',
  socket_endpoint_chat: 'http://localhost:8002/socket-chat',
  socket_endpoint_notification: 'http://localhost:8002/socket-notification',
  apps_url: 'http://localhost:6001',
  base_url: 'http://localhost:5000',
  appslp_url: 'http://localhost:5001',
  docs_url: 'http://localhost:5002',
  board_url: 'http://localhost:5003',
  story_url: 'http://localhost:5004',
  help_url: 'http://localhost:5005/docs',
  folder_url: 'http://localhost:5006',
  blog_url: 'http://localhost:5007',
  jobs_url: 'http://localhost:5008',
  discuss_url: 'http://localhost:5009',
  booking_url: 'http://localhost:5010',
  travel_url: 'http://localhost:5011',
  cookie_domain: 'localhost',
};

const endpoint = process.env.NODE_ENV === 'production' ? ProductionMode : DevelopmentMode;

export default endpoint;

export const s3_space_endpoint = endpoint.s3_cdn_endpoint;
// export const s3_space_endpoint = 'https://s3.ap-northeast-1.wasabisys.com';
export const s3_public_bucket = 'shareup';
