/*
 * AppConstants:for maintenance piao 2019-11-08
 */
// saga - server &&
export const SET_CONTENT = 'shareup/App/SET_CONTENT';
export const SET_CONTENT_SUCCESS = 'shareup/App/SET_CONTENT_SUCCESS';
export const DEL_CONTENT_SUCCESS = 'shareup/App/DEL_CONTENT_SUCCESS';
export const SET_CONTENT_ERROR = 'shareup/App/SET_CONTENT_ERROR';
export const GET_CONTENT = 'shareup/App/GET_CONTENT';
export const GET_CONTENT_SUCCESS = 'shareup/App/GET_CONTENT_SUCCESS';
export const GET_CONTENT_ERROR = 'shareup/App/GET_CONTENT_ERROR';
export const RESET_CONTENTS = 'shareup/App/RESET_CONTENTS';
