/**
 * npmjsからPackageを使う事を止め、CDNから利用する
 * piao 2021-12-18
 */

// import Swal from 'sweetalert2';
// import 'sweetalert2/src/sweetalert2.scss';

export const Swal = window.Swal;

export const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  showConfirmButton: false,
  //= > toastify
  overlay: false,
  useRejections: false,
  timer: 5000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});
export const Confirm = Swal.mixin({
  showCancelButton: true,
});
export const Alert = Swal.mixin({
  showCancelButton: false,
});
