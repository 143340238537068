/**
 * アプリケーションのRoot
 * 1.routeの設定
 * 2.redux-sagaの設定
 * 3.global event設定
 * 4.deviceの情報→store
 * 5.socketの初期化
 * 6.PortalPanel表示
 * ------------------------------------------------------
 * sub-directoryを用途別に分ける。
 * 1.stream関連は/streamに移行。settings/help/misc/supportなどは、独立させる。
 * 2.docs/advt/...など多様なサービスを融合させる為。
 * 3.subdirはrouteのみ、Source自体の格納は、用途別ではなく自体の性質で今まで通りとする。
 * piao 2021-10-29
 * 4.infoMain追加。piao 2022-0303
 */

import React, { StrictMode, useEffect, useCallback } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// containers
import loadable from '@loadable/component';
//
const ProdMain = loadable(() => import('apps/prod'));
const PanelMain = loadable(() => import('apps/panel'));
const BoardMain = loadable(() => import('apps/board'));
const FolderMain = loadable(() => import('apps/folder'));
const DocumentMain = loadable(() => import('apps/document'));
const VideoMain = loadable(() => import('apps/video'));
const ShareMain = loadable(() => import('apps/share'));
const StoriesMain = loadable(() => import('apps/stories'));
const PublicMain = loadable(() => import('containers/PublicMain'));
const LandingPage = loadable(() => import('containers/PublicMain/LandingPage'));
// const SearchMain = loadable(() => import('containers/SearchMain'));
const SignMain = loadable(() => import('components/Authentication/SignMain'));
const UserActivation = loadable(() => import('components/Authentication/UserActivation'));
const UserInvitation = loadable(() => import('components/Authentication/UserInvitation'));
const ResetPassword = loadable(() => import('components/Authentication/ResetPassword'));
// page-not found
const PageNotFound = loadable(() => import('components/uiLibs/page-notfound'));
// redux/saga
// import GlobalContextProvider from 'context/globalContext';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'modules/ErrorFallback';
import ProtectedRoute from './ProtectedRoute';
// socket 2023-0218
import io from 'socket.io-client';
import { useSocket } from 'hooks/useZustand';
import { socketOption } from 'config/settings';
import useIsLogin from 'hooks/useIsLogin';
import endpoint from 'config/endpoint';
import Cookies from 'js-cookie';
import { useQueryClient } from '@tanstack/react-query';
// websocketをContextに適用　2021-06-21
// Saga/ReducerのInjectはAppの最上位で処理する。2021-07-19
export default function App() {
  const queryClient = useQueryClient();
  const [socket, setSocket, resetSocket, clearSocket] = useSocket((state) => [
    state.socket,
    state.setSocket,
    state.resetSocket,
    state.clearSocket,
  ]);
  // ログインのtokenを保存
  const token = Cookies.get('shareup.jp', { domain: endpoint.cookie_domain, path: '/' });
  const isLogin = useIsLogin();

  // socketを初期化する。2023-0218
  useEffect(() => {
    // ログインのみ
    if (isLogin) {
      const instance = io(`${endpoint.socket_endpoint_chat}`, {
        ...socketOption,
        query: { token },
      });
      instance?.on('comment', (comment) => {
        handle_receiveComment(comment);
      });
      instance?.on('connect', () => {
        handle_connect();
      });
      instance?.on('disconnect', () => {
        handle_disconnect();
      });
      resetSocket({
        instance,
        isConnected: instance.connected,
      });
    } else {
      // ログオフの場合、クリア
      clearSocket();
    }
    return () => {
      socket?.instance?.off('connect');
      socket?.instance?.off('disconnect');
      socket?.instance?.off('pong');
    };
  }, [isLogin]);

  const handle_receiveComment = (comment) => {
    // console.log('handle_receiveComment', comment);
    const { type, category, group } = comment;
    const queryKeys = [type, category, group];
    queryClient.invalidateQueries({
      queryKey: queryKeys,
      type: 'active',
      exact: true,
    });
    queryClient.refetchQueries(queryKeys, {
      active: true,
      exact: true,
    });
  };
  const handle_connect = useCallback(() => {
    setSocket({
      isConnected: true,
    });
  }, [socket]);
  const handle_disconnect = useCallback(() => {
    setSocket({
      isConnected: false,
    });
  }, [socket]);

  const myErrorHandler = (error, info) => {
    console.log('myErrorHandler:error', JSON.stringify(error));
    console.log('myErrorHandler:info', JSON.stringify(info));
  };

  return (
    <ErrorBoundary Fallbackelement={ErrorFallback} onError={myErrorHandler}>
      <HelmetProvider>
        <Helmet
          defer={false}
          htmlAttributes={{
            lang: 'jp',
          }}
        >
          <title>動画配信、ドキュメント、ファイル共有を融合したコンテンツ管理・共有ツール</title>
          <meta name="description" content="ナレッジをデザインし創り上げ、共有する" />
          {/* Twitter meta tags below */}
          <meta name="twitter:card" content="summary" />
          {/* <meta name="twitter:image" content="https://shareup.jp/shareupjp-image.webp" /> */}
          <meta
            name="twitter:title"
            content="動画配信、ドキュメント、ファイル共有を融合したコンテンツ管理・共有ツール"
          />
          <meta name="twitter:creator" content="@shareupjp" />
          <meta name="twitter:site" content="@shareupjp" />
          <meta name="twitter:description" content="ナレッジをデザインし創り上げ、共有する" />
          {/* Facebook meta tags below */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="shareup.jp" />
          <meta
            property="og:title"
            content="動画配信、ドキュメント、ファイル共有を融合したコンテンツ管理・共有ツール"
          />
          <meta property="og:description" content="ナレッジをデザインし創り上げ、共有する" />
          {/* <meta property="og:image" content="https://shareup.jp/shareupjp-image.webp" /> */}
        </Helmet>
        {/* <GlobalContextProvider> */}
        <StrictMode>
          <AppComponent />
        </StrictMode>
        {/* </GlobalContextProvider> */}
      </HelmetProvider>
    </ErrorBoundary>
  );
}

function AppComponent() {
  return (
    <Routes>
      <Route path="/public" element={<PublicMain />} />
      <Route element={<ProtectedRoute />}>
        <Route path={'/prod/*'} element={<ProdMain />} />
      </Route>
      <Route path="/panel/:matchId" element={<PanelMain />} />
      <Route path={'/board/*'} element={<BoardMain />} />
      <Route path={'/folder/*'} element={<FolderMain />} />
      <Route path={'/document/*'} element={<DocumentMain />} />
      <Route path={'/video/*'} element={<VideoMain />} />
      <Route path={'/share/*'} element={<ShareMain />} />
      <Route path={'/stories/*'} element={<StoriesMain />} />
      <Route path={'/sign/activation/*'} element={<UserActivation />} />
      <Route path={'/sign/invitation/*'} element={<UserInvitation />} />
      <Route path={'/sign/reset_password/*'} element={<ResetPassword />} />
      <Route path="/sign/:matchType" element={<SignMain />} />
      <Route path="/welcome" element={<LandingPage />} />
      <Route path="/" element={<Navigate to={'/welcome'} />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
