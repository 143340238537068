/*
 * Actions
 */

import { UPDATE_STORE, RESET_STORE, UNSET_STORE } from './storeConstants';

/**
 *UPDATE_STORE
 */
export function updateStore(payload) {
  return {
    type: UPDATE_STORE,
    payload,
  };
}
export function resetStore(payload) {
  return {
    type: RESET_STORE,
    payload,
  };
}
export function unsetStore(payload) {
  return {
    type: UNSET_STORE,
    payload,
  };
}
