/**
 * react v18 / react-router v6対応
 * 1．reduxは使わない。代わりにzusdantを使う
 * 2．
 * piao 2022-0928
 */
// Needed for redux-saga es6 generator support
// import '@babel/polyfill';
import 'regenerator-runtime/runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import requestNotificationPermission from 'utils/requestNotificationPermission';
// tailwindcssのstyle piao 2022-0928
import 'assets/tailwind/style.css';
import 'assets/scss/main.scss';
/**
 * flatpickr
 * 1.themeの設定
 * 2.日本語をDefault
 * 2022-02-11
 */
// import "flatpickr/dist/themes/material_blue.css";
import 'flatpickr/dist/themes/airbnb.css';
import flatpickr from 'flatpickr';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';
flatpickr.localize(Japanese);
// app(main)
import App from 'apps/router';
import localForage from 'localforage';
// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!assets/images/paperplane-dark.png';
import 'file-loader?name=.htaccess!./.htaccess';

import { Workbox } from 'workbox-window';

// Notification 許可
requestNotificationPermission();
/**
 * この場所で共通モジュールをLoadしておく。2022-01-28
 */
import customNanoid from 'utils/customNanoid';
window.customNanoid = customNanoid;
import {
  isTrue,
  isBool,
  isBoolEq,
  isNumberEq,
  hasOneFalse,
  allTrue,
  hasTrue,
  evl,
} from 'utils/methods';
window.isTrue = isTrue;
window.isBool = isBool;
window.isBoolEq = isBoolEq;
window.isNumberEq = isNumberEq;
window.hasOneFalse = hasOneFalse;
window.allTrue = allTrue;
window.hasTrue = hasTrue;
window.evl = evl;
// dayjs
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(LocalizedFormat);
// 相対日時のプラグインを有効化
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
import durationPlugin from 'dayjs/plugin/duration';
dayjs.extend(durationPlugin);
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);
window.dayjs = dayjs;
import PropTypes from 'prop-types';
window.PropTypes = PropTypes;
import { clsx } from 'clsx';
window.clsx = clsx;
import endpoint from 'config/endpoint';
window.endpoint = endpoint;
import { cb_success, cb_failed } from 'components/Notifications/callback';
window.cb_success = cb_success;
window.cb_failed = cb_failed;
import { Toast, Alert, Confirm } from 'components/Notifications';
window.Toast = Toast;
window.Alert = Alert;
window.Confirm = Confirm;
// 2022-1114
import OverlayScrollbars from 'components/overlayscrollbars';
window.OverlayScrollbars = OverlayScrollbars;
// 非常に使用頻度が高い定数 2021-05-30
window.FORMAT_DAYTIME = 'YYYY-MM-DD HH:mm:ss';
window.FORMAT_TIMESTR = 'YYYY-MM-DDHHmmss';
window.FORMAT_DATE = 'YYYY-MM-DD';
/**
 * redux v6対応
 * piao 2022-0928
 */
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
// react V18
const container = document.getElementById('app');

// set refetchOnWindowFocus:false globally piao 2022-0302
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);

/**
 * indexDBに__idを埋め込む。自分を特定する為 2021-1229
 */
localForage.getItem('_id_').then((value) => {
  if (!value) {
    localForage.setItem('_id_', customNanoid());
  }
});

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/sw.js');

    wb.addEventListener('installed', (event) => {
      if (event.isUpdate) {
        if (confirm('新たなプログラムが利用可能です。OKをクリックして更新して下さい。')) {
          window.location.reload();
        }
      }
    });
    wb.addEventListener('activated', (event) => {
      // `event.isUpdate` will be true if another version of the service
      // worker was controlling the page when this version was registered.
      if (!event.isUpdate) {
        console.log('Service worker activated for the first time!');

        // If your service worker is configured to precache assets, those
        // assets should all be available now.
      }
    });

    wb.addEventListener('waiting', (event) => {
      console.log(
        "A new service worker has installed, but it can't activate" +
          'until all tabs running the current version have fully unloaded.',
      );
    });

    wb.addEventListener('message', (event) => {
      if (event.data.type === 'CACHE_UPDATED') {
        const { updatedURL } = event.data.payload;

        console.log(`A newer version of ${updatedURL} is available!`);
      }
    });

    wb.register();
  }
}
