/*
 * Reducer
 *
 */

import { produce, createDraft, finishDraft } from 'immer';
import mixin from 'mixin-deep';
import { UPDATE_STORE, RESET_STORE, UNSET_STORE } from './storeConstants';

// The initial state of the App
export const initialState = {};

/* eslint-disable default-case, no-param-reassign */
export const storeReducer = (state = initialState, action) => {
  if (action.type === RESET_STORE) {
    const newStateDraft = createDraft({
      ...initialState,
      ...(action.payload || {}),
    });
    return finishDraft(newStateDraft);
  }
  return produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_STORE:
        mixin(draft, action.payload || {});
        break;
      case UNSET_STORE:
        // action.payloadは必ず、配列でPathを渡す事
        // action.unsetStore(['unsetTest', 'name', 'first']);
        _.unset(draft, action.payload);
        break;
      default:
        break;
    }
  });
};
