/**
 * jsonをGridで表示する
 * piao 2021-07-06
 * nested object対応 2021-07-02
 */
import React, { useState, useEffect, useCallback } from 'react';
import { View } from 'inlineStyles/css-grid';

import PropTypes from 'prop-types';
import Collapse from 'components/Collapse';

//  共通renderを追加：全てのvalueに適用する。2022-0603
function GridView({
  columns,
  fontSize = '0.8125rem',
  classNames,
  gridClass = '',
  containerClass = '',
  collapseContentClass = 'border p-1',
  data,
  fieldWidth,
  render,
  collapseInitOpen = false,
  collapseEnable = true,
}) {
  const [rows, setRows] = useState({});

  useEffect(() => {
    setRows(data);
  }, [data]);

  const rowsComponent = useCallback(
    (rows, depth) =>
      Object.entries(rows).map(([key, value]) =>
        _.isPlainObject(value) ? (
          <div className={clsx('grid-container', containerClass)} key={`isPlainObject:${key}`}>
            <Collapse
              header={key}
              contentClass={collapseContentClass}
              initOpen={collapseInitOpen}
              collapsable={collapseEnable}
              headerSize="sm"
            >
              {rowsComponent(value, depth + 1)}
            </Collapse>
          </div>
        ) : //  isObjectはobject/arrayを含む。2022-1005
        _.isObject(value) ? (
          <div className={clsx('grid-container', containerClass)} key={`isObject:${key}`}>
            <Collapse
              header={key}
              contentClass={collapseContentClass}
              initOpen={collapseInitOpen}
              collapsable={collapseEnable}
              headerSize="sm"
            >
              <pre className="original-scroll">
                <code>{JSON.stringify(value, null, 2)}</code>
              </pre>
            </Collapse>
          </div>
        ) : (
          <div className="grid-row" key={`row:${key}`}>
            <div className={clsx('grid-item', gridClass)}>{columns?.[key]?.name || key}</div>
            <div className={clsx('grid-item', gridClass)}>
              {columns?.[key]?.render?.(value) ||
                render?.(value) ||
                (value === true ? 'true' : value === false ? 'false' : value)}
            </div>
          </div>
        ),
      ),
    [collapseInitOpen, collapseEnable],
  );

  return (
    <View fontSize={fontSize} className={classNames} fieldWidth={fieldWidth}>
      {rowsComponent(rows, 0)}
    </View>
  );
}

GridView.propTypes = {
  columns: PropTypes.object,
  onChange: PropTypes.func,
  render: PropTypes.func,
  fontSize: PropTypes.string,
  fieldWidth: PropTypes.string,
  gridClass: PropTypes.string,
  containerClass: PropTypes.string,
  collapseContentClass: PropTypes.string,
  data: PropTypes.object,
  classNames: PropTypes.string,
  collapseInitOpen: PropTypes.bool,
  collapseEnable: PropTypes.bool,
};

export default GridView;
