/*
 * Reducer
 *
 */

import {
  SET_CONTAINER,
  SET_CONTAINER_SUCCESS,
  DEL_CONTAINER_SUCCESS,
  SET_CONTAINER_ERROR,
  GET_CONTAINER,
  GET_CONTAINER_SUCCESS,
  GET_CONTAINER_ERROR,
} from './containerConstants';

// The initial state of the App
export const initialState = {
  values: {},
  container: [],
};

/* eslint-disable default-case, no-param-reassign */
export const containerReducer = (state = initialState, action) => {
  try {
    switch (action.type) {
      case SET_CONTAINER:
        return {
          ...state,
          setContainer: true,
          setContainerError: false,
        };
      case SET_CONTAINER_SUCCESS: {
        const { payload } = action;
        if (_.isEmpty(payload)) return state;
        const _ids = Array.isArray(payload)
          ? _.map(payload, (row) => row.xctr_uuid)
          : [payload.xctr_uuid];
        const restContainers = _.filter(
          state.container || [],
          (row) => !_.includes(_ids, row.xctr_uuid),
        );
        return {
          ...state,
          container: restContainers.concat(payload),
          values: {
            ...state.values,
            ...(Array.isArray(payload)
              ? payload.reduce(
                  (initValue, row) => ({
                    ...initValue,
                    [row.xctr_uuid]: row,
                  }),
                  {},
                )
              : {
                  [payload.xctr_uuid]: payload,
                }),
          },
          setContainer: false,
          setContainerError: false,
        };
      }
      case DEL_CONTAINER_SUCCESS: {
        // 削除の場合、削除されたレコードがpayloadにセットされる。
        const { payload } = action;
        if (_.isEmpty(payload)) return state;
        return {
          ...state,
          container: _.filter(state.container, (row) => row.xctr_uuid !== payload.xctr_uuid),
          values: _.omit(state.values, [payload.xctr_uuid]),
          setContainer: false,
          setContainerError: false,
        };
      }
      case SET_CONTAINER_ERROR:
        return {
          ...state,
          setContainer: false,
          setContainerError: true,
          containerError: action.error,
        };

      case GET_CONTAINER:
        return {
          ...state,
          getContainer: true,
          getContainerError: false,
        };
      case GET_CONTAINER_SUCCESS: {
        const { payload } = action;
        if (_.isEmpty(payload)) return state;
        const _ids = Array.isArray(payload)
          ? _.map(payload, (row) => row.xctr_uuid)
          : [payload.xctr_uuid];
        const restContainers = _.filter(
          state.container || [],
          (row) => !_.includes(_ids, row.xctr_uuid),
        );
        return {
          ...state,
          container: restContainers.concat(payload),
          values: {
            ...state.values,
            ...(Array.isArray(payload)
              ? payload.reduce(
                  (initValue, row) => ({
                    ...initValue,
                    [row.xctr_uuid]: row,
                  }),
                  {},
                )
              : {
                  [payload.xctr_uuid]: payload,
                }),
          },
          getContainer: false,
          getContainerError: false,
        };
      }
      case GET_CONTAINER_ERROR:
        return {
          ...state,
          getContainer: false,
          getContainerError: true,
          containerError: action.error,
        };

      default:
        return state;
    }
  } catch (err) {
    console.log(err);
  }
};
