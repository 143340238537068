/**
 * 此処で、各種設定情報を管理する。
 * piao 2020-1209
 */

export const assistantTypeNormal = [
  'helps',
  'notification',
  'addons',
  'tasks',
  'documents',
  'history',
];

export const socketOption = {
  reconnection: true,
  autoConnect: true,
  reconnectionDelay: 5000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: Infinity,
  transports: ['websocket', 'polling'],
  withCredentials: true,
  extraHeaders: {
    'shareup-custom-header': 'shareup.jp',
  },
};
