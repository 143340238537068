const colors = {
  white: '#ffffff',
  'gray-50': '#f9fafb',
  'gray-100': '#f8f9fa',
  'gray-200': '#f4f6f8',
  'gray-300': '#dee2e6',
  'gray-400': '#ced4da',
  'gray-500': '#adb5bd',
  'gray-600': '#868e96',
  'gray-700': '#495057',
  'gray-800': '#343a40',
  'gray-900': '#212529',
  black: '#000',
  'sky-lighter': '#F9FAFB',
  'sky-light': '#F4F6F8',
  sky: '#DFE3E8',
  'sky-dark': '#C4CDD5',
  'ink-lightest': '#919EAB',
  'ink-lighter': '#637381',
  'ink-light': '#454F5B',
  ink: '#212B36',
  light: '#B3B5CB',
  'title-bar': '#43467F',
  dark: '#1C2260',
  darker: '#00044C',
  'purple-lighter': '#F6F0FD',
  'purple-light': '#E3D0FF',
  purple: '#9C6ADE',
  'purple-dark': '#50248F',
  'purple-darker': '#230051',
  'indigo-lighter': '#f4f5fa',
  'indigo-light': '#B3BCF5',
  indigo: '#5c6ac4',
  'indigo-dark': '#202e78',
  'indigo-darker': '#000639',
  'blue-lighter': '#EBF5FA',
  'blue-light': '#B4E1FA',
  blue: '#006FBB',
  'blue-dark': '#084E8A',
  'blue-darker': '#001429',
  'teal-lighter': '#E0F5F5',
  'teal-light': '#B7ECEC',
  teal: '#47C1BF',
  'teal-dark': '#00848E',
  'teal-darker': '#003135',
  'green-lighter': '#E3F1DF',
  'green-light': '#BBE5B3',
  green: '#50B83C',
  'green-dark': '#108043',
  'green-darker': '#173630',
  'yellow-lighter': '#FCF1CD',
  'yellow-light': '#FFEA8A',
  yellow: '#EEC200',
  'yellow-dark': '#8A6116',
  'yellow-darker': '#573B00',
  'orange-lighter': '#FCEBDB',
  'orange-light': '#FFC58B',
  orange: '#F49342',
  'orange-dark': '#C05717',
  'orange-darker': '#4A1504',
  'red-lighter': '#FBEAE5',
  'red-light': '#FEAD9A',
  red: '#DE3618',
  'red-dark': '#BF0711',
  'red-darker': '#330101',
};
export const editorColors = {
  white: '#ffffff',
  'gray-100': '#f9fafb',
  'gray-300': '#dfe3e8',
  'gray-500': '#919eab',
  'gray-800': '#343a40',
  'gray-900': '#212b36',
  black: '#000',
  'sky-light': '#F4F6F8',
  sky: '#DFE3E8',
  'sky-dark': '#C4CDD5',
  'ink-light': '#454F5B',
  ink: '#212B36',
  light: '#B3B5CB',
  'title-bar': '#43467F',
  dark: '#1C2260',
  darker: '#00044C',
  'purple-light': '#E3D0FF',
  purple: '#9C6ADE',
  'purple-dark': '#50248F',
  'purple-darker': '#230051',
  'indigo-light': '#B3BCF5',
  indigo: '#5c6ac4',
  'indigo-dark': '#202e78',
  'indigo-darker': '#000639',
  'blue-light': '#B4E1FA',
  blue: '#006FBB',
  'blue-dark': '#084E8A',
  'blue-darker': '#001429',
  'teal-light': '#B7ECEC',
  teal: '#47C1BF',
  'teal-dark': '#00848E',
  'teal-darker': '#003135',
  'green-light': '#BBE5B3',
  green: '#50B83C',
  'green-dark': '#108043',
  'green-darker': '#173630',
  'yellow-light': '#FFEA8A',
  yellow: '#EEC200',
  'yellow-dark': '#8A6116',
  'yellow-darker': '#573B00',
  'orange-light': 'FFC58B',
  orange: 'F49342',
  'orange-dark': 'C05717',
  'orange-darker': '4A1504',
  'red-light': '#FEAD9A',
  red: '#DE3618',
  'red-dark': '#BF0711',
  'red-darker': '#330101',
};
export const grayArr = [
  '#f9fafb',
  '#f4f6f8',
  '#dfe3e8',
  '#c4cdd5',
  '#919eab',
  '#637381',
  '#343a40',
  '#212b36',
];

export const typeColors = {
  primary: '#886ab5',
  secondary: '#868e96',
  success: '#1dc9b7',
  danger: '#fd3995',
  warning: '#ffc241',
  info: '#2196F3',
};
export const typeColorsArr = [
  '#886ab5',
  '#868e96',
  '#1dc9b7',
  '#fd3995',
  '#ffc241',
  '#2196F3',
];

export const colorPaletteArray = {
  gray: [
    '#f9fafb',
    '#f8f9fa',
    '#f4f6f8',
    '#dee2e6',
    '#ced4da',
    '#adb5bd',
    '#868e96',
    '#495057',
    '#343a40',
    '#212529',
  ],
  primary: [
    '#dfbfd6',
    '#d7aecb',
    '#ce9dc0',
    '#c68cb5',
    '#bd7baa',
    '#b56a9f',
    '#ad5994',
    '#9e4e86',
    '#8d4678',
    '#7c3d69',
  ],
  success: [
    '#7aeca1',
    '#63e991',
    '#4de581',
    '#37e272',
    '#21df62',
    '#1dc958',
    '#1ab34e',
    '#179c44',
    '#13863b',
    '#107031',
  ],
  info: [
    '#9af2fa',
    '#82eff8',
    '#6aebf7',
    '#51e8f6',
    '#39e5f4',
    '#21e2f3',
    '#0ddbee',
    '#0cc5d5',
    '#0aafbd',
    '#0998a5',
  ],
  warning: [
    '#e8ffc1',
    '#deffa7',
    '#d5ff8e',
    '#cbff74',
    '#c2ff5b',
    '#b8ff41',
    '#aeff28',
    '#a5ff0e',
    '#99f400',
    '#89da00',
  ],
  danger: [
    '#e9b7fe',
    '#e19efe',
    '#d985fe',
    '#d16bfe',
    '#c952fd',
    '#c139fd',
    '#b920fd',
    '#b107fc',
    '#a102e7',
    '#9002ce',
  ],
  fusion: [
    '#909090',
    '#838383',
    '#767676',
    'dimgray',
    '#5d5d5d',
    '#505050',
    '#434343',
    '#363636',
    '#2a2a2a',
    '#1d1d1d',
  ],
};
// smartadminより、2022-0221
export const root = {
  '--theme-primary-50': '#bac0e6',
  '--theme-primary-100': '#a7aedf',
  '--theme-primary-200': '#949dd8',
  '--theme-primary-300': '#818cd2',
  '--theme-primary-400': '#6f7bcb',
  '--theme-primary-500': '#5c6ac4',
  '--theme-primary-600': '#4959bd',
  '--theme-primary-700': '#3f4eae',
  '--theme-primary-800': '#38469b',
  '--theme-primary-900': '#313d89',
  '--theme-success-50': '#7aece0',
  '--theme-success-100': '#63e9db',
  '--theme-success-200': '#4de5d5',
  '--theme-success-300': '#37e2d0',
  '--theme-success-400': '#21dfcb',
  '--theme-success-500': '#1dc9b7',
  '--theme-success-600': '#1ab3a3',
  '--theme-success-700': '#179c8e',
  '--theme-success-800': '#13867a',
  '--theme-success-900': '#107066',
  '--theme-info-50': '#9acffa',
  '--theme-info-100': '#82c4f8',
  '--theme-info-200': '#6ab8f7',
  '--theme-info-300': '#51adf6',
  '--theme-info-400': '#39a1f4',
  '--theme-info-500': '#2196F3',
  '--theme-info-600': '#0d8aee',
  '--theme-info-700': '#0c7cd5',
  '--theme-info-800': '#0a6ebd',
  '--theme-info-900': '#0960a5',
  '--theme-warning-50': '#ffebc1',
  '--theme-warning-100': '#ffe3a7',
  '--theme-warning-200': '#ffdb8e',
  '--theme-warning-300': '#ffd274',
  '--theme-warning-400': '#ffca5b',
  '--theme-warning-500': '#ffc241',
  '--theme-warning-600': '#ffba28',
  '--theme-warning-700': '#ffb20e',
  '--theme-warning-800': '#f4a500',
  '--theme-warning-900': '#da9400',
  '--theme-danger-50': '#feb7d9',
  '--theme-danger-100': '#fe9ecb',
  '--theme-danger-200': '#fe85be',
  '--theme-danger-300': '#fe6bb0',
  '--theme-danger-400': '#fd52a3',
  '--theme-danger-500': '#fd3995',
  '--theme-danger-600': '#fd2087',
  '--theme-danger-700': '#fc077a',
  '--theme-danger-800': '#e7026e',
  '--theme-danger-900': '#ce0262',
  '--theme-fusion-50': '#909090',
  '--theme-fusion-100': '#838383',
  '--theme-fusion-200': '#777777',
  '--theme-fusion-300': '#6a6a6a',
  '--theme-fusion-400': '#5d5d5d',
  '--theme-fusion-500': '#505050',
  '--theme-fusion-600': '#444444',
  '--theme-fusion-700': '#373737',
  '--theme-fusion-800': '#2a2a2a',
  '--theme-fusion-900': '#1d1d1d',
};
export const polaris = {
  'white----------': '#ffffff',
  'sky-lighter----': '#F9FAFB',
  'sky-light------': '#F4F6F8',
  'sky------------': '#DFE3E8',
  'sky-dark-------': '#C4CDD5',
  'ink-lightest---': '#919EAB',
  'ink-lighter----': '#637381',
  'ink-light------': '#454F5B',
  'ink------------': '#212B36',
  'black----------': '#000000',
  'purple-lighter-': '#F6F0FD',
  'purple-light---': '#E3D0FF',
  'purple---------': '#9C6ADE',
  'purple-dark----': '#50248F',
  'purple-darker--': '#230051',
  'indigo-lighter-': '#f4f5fa',
  'indigo-light---': '#B3BCF5',
  'indigo---------': '#5c6ac4',
  'indigo-dark----': '#202e78',
  'indigo-darker--': '#000639',
  'blue-lighter---': '#EBF5FA',
  'blue-light-----': '#B4E1FA',
  'blue-----------': '#006FBB',
  'blue-dark------': '#084E8A',
  'blue-darker----': '#001429',
  'teal-lighter---': '#E0F5F5',
  'teal-light-----': '#B7ECEC',
  'teal-----------': '#47C1BF',
  'teal-dark------': '#00848E',
  'teal-darker----': '#003135',
  'green-lighter--': '#E3F1DF',
  'green-light----': '#BBE5B3',
  'green----------': '#50B83C',
  'green-dark-----': '#108043',
  'green-darker---': '#173630',
  'yellow-lighter-': '#FCF1CD',
  'yellow-light---': '#FFEA8A',
  'yellow---------': '#EEC200',
  'yellow-dark----': '#8A6116',
  'yellow-darker--': '#573B00',
  'orange-lighter-': '#FCEBDB',
  'orange-light---': '#FFC58B',
  'orange---------': '#F49342',
  'orange-dark----': '#C05717',
  'orange-darker--': '#4A1504',
  'red-lighter----': '#FBEAE5',
  'red-light------': '#FEAD9A',
  'red------------': '#DE3618',
  'red-dark-------': '#BF0711',
  'red-darker-----': '#330101',
  'theme-primary--': '#b56a9f',
  'theme-secondary': '#6c757d',
  'theme-success--': '#1dc958',
  'theme-info-----': '#21e2f3',
  'theme-warning--': '#b8ff41',
  'theme-danger---': '#c139fd',
  'light----------': '#B3B5CB',
  'title-bar------': '#43467F',
  'dark-----------': '#1C2260',
  'darker---------': '#00044C',
};

export default colors;
