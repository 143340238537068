/**
 * css-grid
 *
 *  piao 2021-04-01
 */

import styled, { css } from 'styled-components';
import colors from './colors';

export const Grid = {
  Container: styled.div`
    display: grid;
    grid-template-columns: ${({ theme }) =>
      theme.gridTemplateColumns ||
      'repeat(auto-fit, minmax(min(20rem, 100%), 1fr))'};
    grid-template-rows: ${({ theme }) =>
      theme.gridTemplateRows && theme.gridTemplateRows};
    grid-auto-rows: ${({ theme }) => theme.gridAutoRows && theme.gridAutoRows};
    grid-gap: ${({ theme }) => theme.gridGap || '0.5rem'};
    padding: ${({ padding }) => padding || '0.75rem'};
    padding-top: ${({ paddingTop }) => paddingTop && paddingTop};
    padding-bottom: ${({ paddingBottom }) => paddingBottom && paddingBottom};
    margin: ${({ margin }) => margin && margin};
    margin-top: ${({ marginTop }) => marginTop && marginTop};
    margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
    width: ${({ width }) => width || '100%'};
  `,
  Item: styled.div`
    grid-column: ${({ theme }) => theme.column};
    grid-row: ${({ theme }) => theme.row};
    height: ${({ height }) => height && height};
    min-height: ${({ minHeight }) => minHeight && minHeight};
    width: ${({ width }) => width && width};
    min-width: ${({ minWidth }) => minWidth && minWidth};
    padding: ${({ padding }) => padding && padding};
    margin: ${({ margin }) => margin && margin};
  `,
};

export const GridItem = styled.div`
  width: 100%;
  height: 100%;
  grid-column: ${({ start = 'auto', end = 'auto' }) => `${start}/${end}`};
`;

// 入力Form
export const Form = {
  Container: styled.div`
    display: grid;
    grid-template-columns: ${({ gridTemplateColumns }) =>
    gridTemplateColumns || '0.25fr 0.25fr 0.25fr 0.25fr'};
    grid-template-rows: ${({ gridTemplateRows }) =>
    gridTemplateRows && gridTemplateRows};
    grid-column-gap: ${({ gridColumnGap }) => gridColumnGap || '10px'};
    grid-row-gap: ${({ gridRowGap }) => gridRowGap || '10px'};
    grid-gap: ${({ gridGap }) => gridGap && gridGap};

    .grid-form {
      display: flex;
      flex-direction: column;
    }
    .grid-label {
      text-align: left;
      align-self: stretch;
      padding: 0.25rem;
      line-height: 1.25rem;
      width: ${({ fieldWidth }) => fieldWidth || '100%'};
    }
    .grid-input {
      text-align: left;
      align-self: stretch;
      border: 1px solid ${colors['gray-300']};
      padding: 0;
      width: ${({ fieldWidth }) => fieldWidth || '100%'};
    }
    .form-control {
      border-radius: 0 !important;
      border: none !important;
    }
  `,
  Item: styled.div`
    grid-column: ${({ gridColumn }) => gridColumn && gridColumn};
    grid-row: ${({ gridRow }) => gridRow && gridRow};
    grid-row-start: ${({ gridRowStart }) => gridRowStart && gridRowStart};
    grid-row-end: ${({ gridRowEnd }) => gridRowEnd && gridRowEnd};
    grid-column-start: ${({ gridColumnStart }) =>
    gridColumnStart && gridColumnStart};
    grid-column-end: ${({ gridColumnEnd }) => gridColumnEnd && gridColumnEnd};
  `,
};

const fieldBackground = css`
  background: linear-gradient(
    to bottom,
    ${colors['sky-lighter']} 0%,
    ${colors['indigo-lighter']} 100%
  );
`;

// GridView用
export const View = styled.div`
  padding: 0;
  width: ${({ width }) => width && width};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  font-size: ${({ fontSize }) => fontSize || '13px'};

  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
    border: 1px solid ${colors['gray-300']};
  }
  .grid-row {
    display: grid;
    grid-template-columns: ${({ fieldWidth }) => `${fieldWidth || '1fr'} 1fr`};
    grid-gap: 0;
    border-left: 1px solid ${colors['gray-300']};
    border-right: 1px solid ${colors['gray-300']};

    &:first-child {
      border-top: 1px solid ${colors['gray-300']};
    }
    &:not(:first-child) {
    }
  }
  .grid-item {
    text-align: left;
    align-self: stretch;
    border-bottom: 1px solid ${colors['gray-300']};
    border-right: 1px solid ${colors['gray-300']};
    padding: 0.25rem;

    &:first-child {
      ${fieldBackground};
    }
    &:last-child {
      border-right: 0;
      padding: ${({ editable }) => (editable ? '0' : '0.25rem')};
    }
  }
`;
// GridView用
export const EditStyles = styled.div`
  padding: 0;
  width: ${({ width }) => width && width};
  max-width: ${({ maxWidth }) => maxWidth && maxWidth};
  font-size: ${({ fontSize }) => fontSize || '13px'};

  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
    border: 1px solid ${colors['gray-300']};
  }

  .grid-row {
    display: grid;
    grid-template-columns: ${({ fieldWidth }) => `${fieldWidth || '1fr'} 1fr`};
    grid-gap: 0;
    border-left: 1px solid ${colors['gray-300']};
    border-right: 1px solid ${colors['gray-300']};

    &:first-child {
      border-top: 1px solid ${colors['gray-300']};
    }
    &:not(:first-child) {
    }
  }

  .grid-item {
    text-align: left;
    align-self: stretch;
    border-bottom: 1px solid ${colors['gray-300']};
    border-right: 1px solid ${colors['gray-300']};
    padding: 0.25rem;
    width: 100%;

    &:first-child {
      ${fieldBackground};
    }
    &:last-child {
      border-right: 0;
      padding: ${({ editable }) => (editable ? '0' : '0.25rem')};
    }
  }
  .grid-item.field {
    ${fieldBackground};
  }
  .grid-item.editable {
    border-right: 0;
    padding: 0;
  }
`;

export const GridLayoutsExample = styled.div`
  .container {
    display: grid;
    grid-template-columns: 0.4fr 0.3fr 0.3fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  .grid {
    background-color: #444;
    color: #fff;
    padding: 25px;
    font-size: 3rem;
  }
  .header {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
    background-color: #55d4eb;
  }

  .hero {
    grid-column: 1 / 2;
    grid-row-start: 2 / 3;
    background-color: #d5c9e2;
    min-height: 300px;
  }

  .sidebar {
    grid-column: 1 / 2;
    grid-row: 3 / 5;
    min-height: 400px;
    background-color: #a0c263;
  }

  .content {
    grid-column: 2 / 4;
    grid-row: 2 / 4;
    min-height: 500px;
    background-color: #f5c531;
  }

  .extra {
    grid-column: 2 / 4;
    grid-row: 4 / 5;
    min-height: 200px;
    background-color: #898989;
  }

  .related-images {
    grid-column: 1 / 3;
    grid-row: 5 / 6;
    min-height: 150px;
    background-color: #2bb673;
  }

  .related-posts {
    grid-column: 3 / 4;
    grid-row: 5 / 6;
    background-color: #f3cddd;
  }

  .footer {
    grid-column: 1 / 4;
    grid-row: 6 / 7;
    background-color: #ffa500;
  }
`;
