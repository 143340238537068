/*
 * Reducer
 *
 */

import {
  SET_CONTENT,
  SET_CONTENT_SUCCESS,
  DEL_CONTENT_SUCCESS,
  SET_CONTENT_ERROR,
  GET_CONTENT,
  GET_CONTENT_SUCCESS,
  GET_CONTENT_ERROR,
  RESET_CONTENTS,
} from './contentConstants';

// The initial state of the App
export const initialState = {
  values: {},
  content: [],
};

/* eslint-disable default-case, no-param-reassign */
export const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CONTENTS:
      return {
        values: {},
        content: [],
        container: [],
        info: {},
        event: [],
        resetContents: true,
        resetContentsError: false,
      };
    case SET_CONTENT:
      return {
        ...state,
        setContent: true,
        setContentError: false,
      };
    case SET_CONTENT_SUCCESS: {
      const { payload } = action;
      if (_.isEmpty(payload)) return state;
      const _ids = Array.isArray(payload)
        ? _.map(payload, (row) => row.xc_uuid)
        : [payload.xc_uuid];
      const restContents = _.filter(state.content || [], (row) => !_.includes(_ids, row.xc_uuid));
      return {
        ...state,
        content: restContents.concat(payload),
        values: {
          ...state.values,
          ...(Array.isArray(payload)
            ? payload.reduce(
                (initValue, row) => ({
                  ...initValue,
                  [row.xc_uuid]: row,
                }),
                {},
              )
            : {
                [payload.xc_uuid]: payload,
              }),
        },
        setContent: false,
        setContentError: false,
      };
    }
    case DEL_CONTENT_SUCCESS: {
      // 削除の場合、削除されたレコードがpayloadにセットされる。
      const { payload } = action;
      if (_.isEmpty(payload)) return state;
      return {
        ...state,
        content: _.filter(state.content, (row) => row.xc_uuid !== payload.xc_uuid),
        values: _.omit(state.values, [payload.xc_uuid]),
        setContent: false,
        setContentError: false,
      };
    }
    case SET_CONTENT_ERROR:
      return {
        ...state,
        setContent: false,
        setContentError: true,
        contentError: action.error,
      };

    case GET_CONTENT:
      return {
        ...state,
        getContent: true,
        getContentError: false,
      };
    case GET_CONTENT_SUCCESS: {
      const { payload } = action;
      if (_.isEmpty(payload)) return state;
      const _ids = Array.isArray(payload)
        ? _.map(payload, (row) => row.xc_uuid)
        : [payload.xc_uuid];
      const restContents = _.filter(state.content || [], (row) => !_.includes(_ids, row.xc_uuid));
      return {
        ...state,
        content: restContents.concat(payload),
        values: {
          ...state.values,
          ...(Array.isArray(payload)
            ? payload.reduce(
                (initValue, row) => ({
                  ...initValue,
                  [row.xc_uuid]: row,
                }),
                {},
              )
            : {
                [payload.xc_uuid]: payload,
              }),
        },
        getContent: false,
        getContentError: false,
      };
    }
    case GET_CONTENT_ERROR:
      return {
        ...state,
        getContent: false,
        getContentError: true,
        contentError: action.error,
      };

    default:
      return state;
  }
};
