/**
 * actionをwrapper
 * 1．基本的にsaga経由、storeに保存する
 * 2．非同期、直接Return値を使わない。
 * piao 2021-03-13
 */
import { useSelector, useDispatch } from 'react-redux';
import { updateStore, resetStore, unsetStore } from 'apps/router/storeActions';
import useApi from 'hooks/useApi';
import { useUsers } from 'hooks/useZustand';
import endpoint from 'config/endpoint';
import Cookies from 'js-cookie';
import localForage from 'localforage';

export default function useAction() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.store);
  const [user, resetUser, setUser, clearUser] = useUsers((state) => [
    state.user,
    state.resetUser,
    state.setUser,
    state.clearUser,
  ]);
  const api = useApi();
  // ログインのtokenを保存

  return {
    // global
    getUser(path) {
      return (path ? _.get(user, path) : user) || {};
    },
    setUser(payload) {
      api.postUsers({
        ...payload,
        method: 'set_profile',
        cb: {
          success: (resInfo) => {
            setUser(resInfo);
          },
        },
      });
    },
    logoutUser() {
      clearUser();
      Cookies.remove('shareup.jp', { domain: endpoint.cookie_domain, path: '/' });
    },
    signupUser(payload) {
      api.postUsers({
        ...payload,
        method: 'signup',
        cb: {
          success: (resInfo) => {
            resetUser(resInfo);
            Cookies.set('shareup.jp', resInfo?.token, {
              domain: endpoint.cookie_domain,
              path: '/',
            });
            localForage.setItem('token', resInfo?.token);
          },
        },
      });
    },
    getUserProfile(payload) {
      api.postUsers({
        ...payload,
        method: 'get_profile',
        cb: {
          success: (resInfo) => {
            resetUser(resInfo);
            Cookies.set('shareup.jp', resInfo?.token, {
              domain: endpoint.cookie_domain,
              path: '/',
            });
            localForage.setItem('token', resInfo?.token);
          },
        },
      });
    },
    // setUserToken(payload) {
    //   api.postUsers({
    //     ...payload,
    //     method: 'set_accessToken',
    //     cb: {
    //       success: (resInfo) => {
    //         resetUser(resInfo);
    //         Cookies.set('shareup.jp', resInfo?.token, {
    //           domain: endpoint.cookie_domain,
    //           path: '/',
    //         });
    //         localForage.setItem('token', resInfo?.token);
    //       },
    //     },
    //   });
    // },
    // store
    setStore(payload) {
      dispatch(updateStore(payload));
    },
    resetStore(payload) {
      dispatch(resetStore(payload));
    },

    unsetStore(payload) {
      dispatch(unsetStore(payload));
    },
    getStore(path) {
      return path ? _.get(store, path) : store;
    },
  };
}
