/**
 * APIをwrapper
 * 1．直接axiosのapiをCall
 * 2．主にCallback利用
 * 3．awaitでreturn値利用可能
 * piao 2021-06-28
 * 4.createの場合、data/...dataを確り考えて決める事。
 * piao 2022-0228
 */
import React from 'react';
import authAxios from 'utils/auth-axios';
import { catchErrWinbox } from 'utils/catch-errWinbox';
import qs from 'qs';
import endpoint from 'config/endpoint';

export default function useApi() {
  return {
    /**
     * 直接APIアクセス.paramsはactionと同じ形式で…
     * piao 2021-06-25
     */
    async sqlQuery(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/sql_query',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
          return resData;
        }
        return {};
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * 直接APIアクセス.paramsはactionと同じ形式で…
     * piao 2021-07-18
     */
    async sqlUpdate(params) {
      const { data, method, model, cb } = params;
      try {
        const res = await authAxios.post(
          '/sql_update',
          qs.stringify({
            data,
            method,
            model,
          }),
        );
        const { data: resData, message } = res.data;
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
          return resData;
        } else {
          cb?.failed?.(message);
          return message;
        }
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * api関連:token検証＆情報取得
     */
    async getTokenInfo(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/get_tokenInfo',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * api関連:passwordリセット
     */
    async issueResetPassword(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/issue_resetPassword',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    async resetPassword(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/reset_password',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * api関連:user有効化
     */
    async userActivation(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/user_activation',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    async userInvitation(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/user_invitation',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * api関連：メールアドレス確認発行
     */
    async reset_userActivation(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/reset_userActivation',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * api関連：お問い合わせ
     */
    async contact_us(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/contact_us',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * api関連：htmlEmail送信
     */
    async send_htmlEmail(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/send_htmlEmail',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * api関連：password変更
     */
    async change_password(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/change_password',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * account関連
     * 1.postに一本化
     * piao 2022-03-05
     */
    async postAccount(params) {
      const { data, cb, method } = params;
      try {
        let res;
        switch (method) {
          case 'get_maccount':
            res = await authAxios.post('/get_maccount');
            break;
          case 'create_xaccount':
            res = await authAxios.post(
              '/create_xaccount',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'read_xaccount':
            res = await authAxios.post(
              '/read_xaccount',
              qs.stringify({
                xa_uuid: data.xa_uuid,
              }),
            );
            break;
          case 'get_xaccounts':
            res = await authAxios.post(
              '/get_xaccounts',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message, errCode, details } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message, errCode, details);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: (
            <div className="flex flex-col">
              <span>apiアクセスでエラー発生しています。詳細を確認して対処して下さい</span>
              <span className="pt-2 font-bold text-info">
                アクセストークンが失効(Jsonトークンエラー)している場合、開いている画面はログインが必要な場合があります。
              </span>
            </div>
          ),
          data: params.data,
        });
      }
    },
    /**
     * promotion関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     */
    async postPromotion(params) {
      const { data, cb, method } = params;
      try {
        // dataとして渡す為。...data->data
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_prom',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_prom',
              qs.stringify({
                data,
                xprom_uuid: data.xprom_uuid,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_prom',
              qs.stringify({
                xprom_uuid: data.xprom_uuid,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_prom',
              qs.stringify({
                xprom_uuid: data.xprom_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_proms',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * 通知連絡関連
     * piao 2021-1025
     */
    async postNotifications(params) {
      const { data, cb, method } = params;
      try {
        // dataとして渡す為。...data->data
        let res;
        switch (method) {
          case 'send':
            res = await authAxios.post(
              '/send_message',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * passportで認証実装
     * piao 2023-0312
     */
    async postOAuth(params) {
      const { data, cb, method } = params;
      try {
        let res;
        switch (method) {
          case 'github-fetch':
            res = await fetch(`${endpoint.api_endpoint}/auth/github`, {
              method: 'GET',
              credentials: 'include',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true,
              },
            });
            break;
          case 'github':
            res = await authAxios.post(
              '/auth/github',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'google':
            res = await authAxios.post(
              '/auth/google',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        // console.log('postOAuth', res);
        const { data: resData, message } = res?.data || {};
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        console.log('postOAuth', err);
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * user情報取得：UserMaintenance用.自分以外のUserの情報を処理する。
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     * 3.ここでは
     * piao 2022-02-16
     */
    async postUsers(params) {
      const { data, cb, method } = params;
      try {
        // dataとして渡す為。...data->data
        let res;
        switch (method) {
          case 'login':
            res = await authAxios.post(
              '/login',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'signup':
            res = await authAxios.post(
              '/signup',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'set_profile':
            res = await authAxios.post(
              '/set_profile',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'get_profile':
            res = await authAxios.post(
              '/get_profile',
              qs.stringify({
                ...data,
              }),
            );
            break;
          // case 'set_accessToken':
          //   res = await authAxios.post(
          //     '/set_accessToken',
          //     qs.stringify({
          //       ...data,
          //     }),
          //   );
          //   break;
          case 'gets':
            res = await authAxios.post(
              '/get_userProfiles',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'set_userProfile':
            // 渡しデータはdata:{}get_userProfilesとは異なる
            res = await authAxios.post(
              '/set_userProfile',
              qs.stringify({
                data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * documents関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     */
    async postDocuments(params) {
      const { data, cb, method } = params;
      try {
        // dataとして渡す為。...data->data
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_doc',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_doc',
              qs.stringify({
                data,
                xdoc_uuid: data.xdoc_uuid,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_doc',
              qs.stringify({
                xdoc_uuid: data.xdoc_uuid,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_doc',
              qs.stringify({
                xdoc_uuid: data.xdoc_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_docs',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'search':
            res = await authAxios.post(
              '/search_docs',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * Navigations関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     */
    async postNavigations(params) {
      const { data, cb, method } = params;
      try {
        // dataとして渡す為。...data->data
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_nav',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_nav',
              qs.stringify({
                data,
                xnav_uuid: data.xnav_uuid,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_nav',
              qs.stringify({
                xnav_uuid: data.xnav_uuid,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_nav',
              qs.stringify({
                xnav_uuid: data.xnav_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_navs',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'search':
            res = await authAxios.post(
              '/search_navs',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * contracts関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     */
    async postContracts(params) {
      const { data, cb, method } = params;
      try {
        // dataとして渡す為。...data->data
        let res;
        switch (method) {
          case 'replace':
            res = await authAxios.post(
              '/replace_contract',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'create':
            res = await authAxios.post(
              '/create_contract',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_contract',
              qs.stringify({
                data,
                xcontr_uuid: data.xcontr_uuid,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_contract',
              qs.stringify({
                xcontr_uuid: data.xcontr_uuid,
              }),
            );
            break;
          case 'delete_byKey':
            res = await authAxios.post(
              '/delete_contract_byKey',
              qs.stringify({
                xcontr_contractor: data.xcontr_contractor,
                xcontr_target: data.xcontr_target,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_contract',
              qs.stringify({
                xcontr_uuid: data.xcontr_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_contracts',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'replace_array':
            res = await authAxios.post(
              '/replace_contract_array',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'delete_byKey_array':
            res = await authAxios.post(
              '/delete_contract_byKey_array',
              qs.stringify({
                data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * distribs関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     */
    async postDistribs(params) {
      const { data, cb, method } = params;
      try {
        // dataとして渡す為。...data->data
        let res;
        switch (method) {
          // 配列で入れ替える
          case 'create_array':
            res = await authAxios.post(
              '/create_distribs_array',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'create':
            res = await authAxios.post(
              '/create_distribs',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'update':
            {
              if (!data.xd_uuid) {
                Toast.fire({
                  icon: 'warning',
                  text: 'IDを指定して更新可能です。処理できません。',
                });
                return;
              }
              res = await authAxios.post(
                '/upd_distribs',
                qs.stringify({
                  data,
                  xd_uuid: data.xd_uuid,
                }),
              );
            }
            break;
          // 配列で削除
          case 'delete_array':
            res = await authAxios.post(
              '/delete_distribs_array',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'delete':
            {
              if (!data.xd_uuid) {
                Toast.fire({
                  icon: 'warning',
                  text: 'IDを指定して更新可能です。処理できません。',
                });
                return;
              }
              res = await authAxios.post(
                '/delete_distribs',
                qs.stringify({
                  xd_uuid: data.xd_uuid,
                }),
              );
            }
            break;
          case 'read':
            res = await authAxios.post(
              '/read_distribs',
              qs.stringify({
                xd_uuid: data.xd_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_distribs',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * messages関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     */
    async postMessages(params) {
      const { data, cb, method } = params;
      try {
        // dataとして渡す為。...data->data
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_message',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_message',
              qs.stringify({
                xmsg_uuid: data.xmsg_uuid,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_message',
              qs.stringify({
                data,
                xmsg_uuid: data.xmsg_uuid,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_message',
              qs.stringify({
                xmsg_uuid: data.xmsg_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_messages',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * payment(支払い方法)関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     */
    async postPayments(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_payment',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_payment',
              qs.stringify({
                mpay_uuid: data.mpay_uuid,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_payment',
              qs.stringify({
                mpay_uuid: data.mpay_uuid,
                data,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_payment',
              qs.stringify({
                mpay_uuid: data.mpay_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_payments',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * purchase(購入)関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     */
    async postPurchases(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_purchase',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_purchase',
              qs.stringify({
                xpur_uuid: data.xpur_uuid,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_purchase',
              qs.stringify({
                xpur_uuid: data.xpur_uuid,
                data,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_purchase',
              qs.stringify({
                xpur_uuid: data.xpur_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_purchases',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * product(製品)関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     */
    async postProducts(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_product',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_product',
              qs.stringify({
                xprod_uuid: data.xprod_uuid,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_product',
              qs.stringify({
                xprod_uuid: data.xprod_uuid,
                data,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_product',
              qs.stringify({
                xprod_uuid: data.xprod_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_products',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * payment-history(支払い方法)関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     */
    async postPayhis(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_payhis',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_payhis',
              qs.stringify({
                xphis_uuid: data.xphis_uuid,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_payhis',
              qs.stringify({
                xphis_uuid: data.xphis_uuid,
                data,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_payhis',
              qs.stringify({
                xphis_uuid: data.xphis_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_payhis',
              qs.stringify({
                ...data,
              }),
            );
            break;
          // stripe関連
          case 'stripe_payment':
            res = await authAxios.post(
              '/stripe_payment',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * xinfo_のAPI
     * piao 2022-0227
     */
    async postInfos(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_info',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_info',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_info',
              qs.stringify({
                xinfo_uuid: data.xinfo_uuid,
                data,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_info',
              qs.stringify({
                xinfo_uuid: data.xinfo_uuid,
              }),
            );
            break;
          case 'set_pathInfo':
            res = await authAxios.post(
              '/set_pathInfo',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'unset_pathInfo':
            res = await authAxios.post(
              '/unset_pathInfo',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'get_infos':
            res = await authAxios.post(
              '/get_infos',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'get_pathInfo':
            res = await authAxios.post(
              '/get_pathInfo',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'search':
            res = await authAxios.post(
              '/search_infos',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message, errCode, details } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message, errCode, details);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: (
            <div className="flex flex-col">
              <span>apiアクセスでエラー発生しています。詳細を確認して対処して下さい</span>
              <span className="pt-2 font-bold text-info">
                アクセストークンが失効(Jsonトークンエラー)している場合、開いている画面はログインが必要な場合があります。
              </span>
            </div>
          ),
          data: params.data,
        });
      }
    },
    /**
     * xelm_のAPI
     * piao 2022-1008
     */
    async postElements(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_element',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_element',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_element',
              qs.stringify({
                xelm_uuid: data.xelm_uuid,
                data,
              }),
            );
            break;
          case 'copy':
            res = await authAxios.post(
              '/copy_element',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_element',
              qs.stringify({
                xelm_uuid: data.xelm_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_elements',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'search':
            res = await authAxios.post(
              '/search_elements',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message, errCode, details } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message, errCode, details);
        }
        return resData;
      } catch (err) {
        console.log('postElements', err);
        catchErrWinbox(err)({
          message: (
            <div className="flex flex-col">
              <span>apiアクセスでエラー発生しています。詳細を確認して対処して下さい</span>
              <span className="pt-2 font-bold text-info">
                アクセストークンが失効(Jsonトークンエラー)している場合、開いている画面はログインが必要な場合があります。
              </span>
            </div>
          ),
          data: params.data,
        });
      }
    },
    /**
     * xstor_のAPI
     * piao 2022-1106
     */
    async postStories(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_story',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_story',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_story',
              qs.stringify({
                xstor_uuid: data.xstor_uuid,
                data,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_story',
              qs.stringify({
                xstor_uuid: data.xstor_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_stories',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message, errCode, details } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message, errCode, details);
        }
        return resData;
      } catch (err) {
        console.log('postStories', err);
        catchErrWinbox(err)({
          message: (
            <div className="flex flex-col">
              <span>apiアクセスでエラー発生しています。詳細を確認して対処して下さい</span>
              <span className="pt-2 font-bold text-info">
                アクセストークンが失効(Jsonトークンエラー)している場合、開いている画面はログインが必要な場合があります。
              </span>
            </div>
          ),
          data: params.data,
        });
      }
    },
    /**
     * xdat_のAPI
     * piao 2022-1107
     */
    async postDats(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_dat',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_dat',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_dat',
              qs.stringify({
                xdat_uuid: data.xdat_uuid,
                data,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_dat',
              qs.stringify({
                xdat_uuid: data.xdat_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_dats',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message, errCode, details } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message, errCode, details);
        }
        return resData;
      } catch (err) {
        console.log('postDats', err);
        catchErrWinbox(err)({
          message: (
            <div className="flex flex-col">
              <span>apiアクセスでエラー発生しています。詳細を確認して対処して下さい</span>
              <span className="pt-2 font-bold text-info">
                アクセストークンが失効(Jsonトークンエラー)している場合、開いている画面はログインが必要な場合があります。
              </span>
            </div>
          ),
          data: params.data,
        });
      }
    },
    /**
     * 各種統計数字
     * piao 2022-1125
     */
    async postStats(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'count_menuChildren':
            res = await authAxios.post(
              '/count_menuChildren',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message, errCode, details } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message, errCode, details);
        }
        return resData;
      } catch (err) {
        console.log('postStats', err);
        catchErrWinbox(err)({
          message: (
            <div className="flex flex-col">
              <span>apiアクセスでエラー発生しています。詳細を確認して対処して下さい</span>
              <span className="pt-2 font-bold text-info">
                アクセストークンが失効(Jsonトークンエラー)している場合、開いている画面はログインが必要な場合があります。
              </span>
            </div>
          ),
          data: params.data,
        });
      }
    },
    /**
     * JobsのAPI
     * piao 2022-0307
     */
    async postJobs(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'purge_pattern':
            res = await authAxios.post(
              '/purge_pattern',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'jobs_add':
            res = await authAxios.post(
              '/jobs_add',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'jobs_obliterate':
            res = await authAxios.post(
              '/jobs_obliterate',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'jobs_drain':
            res = await authAxios.post(
              '/jobs_drain',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'jobs_info':
            res = await authAxios.post(
              '/jobs_info',
              qs.stringify({
                data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message, details } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message, details);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: (
            <div className="flex flex-col">
              <span>apiアクセスでエラー発生しています。詳細を確認して対処して下さい</span>
              <span className="pt-2 font-bold text-info">
                アクセストークンが失効(Jsonトークンエラー)している場合、開いている画面はログインが必要な場合があります。
              </span>
            </div>
          ),
          data: params.data,
        });
      }
    },
    /**
     * xf_のAPI
     * piao 2022-0306
     */
    async postFiles(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'update':
            res = await authAxios.post(
              '/upd_file',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'copy':
            res = await authAxios.post(
              '/copy_file',
              qs.stringify({
                ...data,
              }),
            );
            break;
          // 以下はすべて...dataで渡す
          case 'get_files':
            res = await authAxios.post(
              '/get_files',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'upload':
            res = await authAxios.post(
              '/upload_file',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'download':
            res = await authAxios.post(
              '/download_file',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'delFiles':
            res = await authAxios.post(
              '/del_files',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'delParentsFiles':
            res = await authAxios.post(
              '/del_parentsfiles',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, url, message, errCode, details } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData, url);
        } else {
          cb?.failed?.(message, errCode, details);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: (
            <div className="flex flex-col">
              <span>apiアクセスでエラー発生しています。詳細を確認して対処して下さい</span>
              <span className="pt-2 font-bold text-info">
                アクセストークンが失効(Jsonトークンエラー)している場合、開いている画面はログインが必要な場合があります。
              </span>
            </div>
          ),
          data: params.data,
        });
      }
    },
    /**
     * sharing(共有)関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     */
    async postSharings(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_sharing',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_sharing',
              qs.stringify({
                xshar_uuid: data.xshar_uuid,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_sharing',
              qs.stringify({
                xshar_uuid: data.xshar_uuid,
                data,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_sharing',
              qs.stringify({
                xshar_uuid: data.xshar_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_sharings',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'release':
            res = await authAxios.post(
              '/release_sharings',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message, errCode, details } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message, errCode, details);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: (
            <div className="flex flex-col">
              <span>apiアクセスでエラー発生しています。詳細を確認して対処して下さい</span>
              <span className="pt-2 font-bold text-info">
                アクセストークンが失効(Jsonトークンエラー)している場合、開いている画面はログインが必要な場合があります。
              </span>
            </div>
          ),
          data: params.data,
        });
      }
    },
    /**
     * container関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     * 3.react-queryと組み合わせて、単独処理
     * piao 2022-0425
     */
    async postContainer(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_container',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_container',
              qs.stringify({
                xctr_uuid: data.xctr_uuid,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_container',
              qs.stringify({
                data,
                xctr_uuid: data.xctr_uuid,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_container',
              qs.stringify({
                xctr_uuid: data.xctr_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_containers',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message, errCode, details } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message, errCode, details);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: (
            <div className="flex flex-col">
              <span>apiアクセスでエラー発生しています。詳細を確認して対処して下さい</span>
              <span className="pt-2 font-bold text-info">
                アクセストークンが失効(Jsonトークンエラー)している場合、開いている画面はログインが必要な場合があります。
              </span>
            </div>
          ),
          data: params.data,
        });
      }
    },
    /**
     * content関連
     * 1.sagaのCallと同じparamsを
     * 2.I/F一本化
     * 3.react-queryと組み合わせて、単独処理
     * piao 2022-0501
     */
    async postContent(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'create':
            res = await authAxios.post(
              '/create_content',
              qs.stringify({
                data,
              }),
            );
            break;
          case 'read':
            res = await authAxios.post(
              '/read_content',
              qs.stringify({
                xc_uuid: data.xc_uuid,
              }),
            );
            break;
          case 'update':
            res = await authAxios.post(
              '/upd_content',
              qs.stringify({
                data,
                xc_uuid: data.xc_uuid,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_content',
              qs.stringify({
                xc_uuid: data.xc_uuid,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/get_contents',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'search':
            res = await authAxios.post(
              '/search_contents',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message, errCode, details } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message, errCode, details);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: (
            <div className="flex flex-col">
              <span>apiアクセスでエラー発生しています。詳細を確認して対処して下さい</span>
              <span className="pt-2 font-bold text-info">
                アクセストークンが失効(Jsonトークンエラー)している場合、開いている画面はログインが必要な場合があります。
              </span>
            </div>
          ),
          data: params.data,
        });
      }
    },
    /**
     * share access link関連,Interactive 要素が異なる為、postSharingsから独立
     * 1.useQuery/ALSwitchWrapperとSetで実装する
     * 2.I/F一本化
     * 3.ここでは、エラーの判断をしない。SVRから返されたデータをそのまま、Call元に返す。
     * 例外は既存通り。
     */
    async postAccessLinks(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'get_linkInfo':
            res = await authAxios.post(
              '/get_linkInfo',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'get_shareInfo':
            res = await authAxios.post(
              '/get_shareInfo',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        return res.data;
      } catch (err) {
        catchErrWinbox(err)({
          message: (
            <div className="flex flex-col">
              <span>apiアクセスでエラー発生しています。詳細を確認して対処して下さい</span>
              <span className="pt-2 font-bold text-info">
                アクセストークンが失効(Jsonトークンエラー)している場合、開いている画面はログインが必要な場合があります。
              </span>
            </div>
          ),
          data: params.data,
        });
      }
    },
    /**
     * S3のgetSignedUrlを管理。actionから移行。
     * 1.useRequestで重複請求を防止
     * 2.useActionから移行
     *  1).getFilesrc,
     *  2).getMediasrc
     *  3).getSignedurl
     * 3.action.getSignedurl(params)は自動的にvaluesを更新するが、ここは付帯処理は行わない。
     * piao 20220124
     */
    async postGeturl(params) {
      const { data, cb, method } = params;
      try {
        // dataの内容を渡す
        let res;
        switch (method) {
          case 'filesrc':
            res = await authAxios.post(
              '/download_file',
              qs.stringify({
                ...data,
                getUrl: true,
                expires: 7 * 24 * 60 * 60,
              }),
            );
            break;
          case 'mediasrc':
            res = await authAxios.post(
              '/download_media',
              qs.stringify({
                ...data,
                expires: 60 * 60,
              }),
            );
            break;
          case 'signedurl':
            res = await authAxios.post(
              '/get_signedUrl',
              qs.stringify({
                ...data,
                expires: 7 * 24 * 3600,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message, url } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData, url);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * redis関連.sagaでCallするAPIと同じ構造にする。
     * 1.keyの値を取得：
     */
    async getRedisInfo(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/get_redisInfo',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    async setRedisInfo(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/set_redisInfo',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    async delRedisInfo(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/del_redisInfo',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    //  findで一覧を取得
    async findRedisInfo(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/find_redisKeys',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        console.log('useApi.findRedisInfo', err);
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * cleanup 特定Key関連情報を抹消
     * 1.data(pattern(messages:xxxxxx:*)とする)
     * 2．delRedisInfoの機能を含む
     * piao 2022-0824
     */
    async cleanupRedisInfo(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/cleanup_redisKeys',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        console.log('useApi.findRedisInfo', err);
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * redisのclientのapi実装。paramsは配列でkey、引数複数指定可能
     * piao 2020-09-27　↓例
     */
    // {
    // 	"command":"setex",
    //    "params":["password",10,"piao.changzhu@gmail.com"]
    // }
    async apiRedisClient(params) {
      const { data, cb } = params;
      try {
        const res = await authAxios.post(
          '/api_redisClient',
          qs.stringify({
            ...data,
          }),
        );
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        catchErrWinbox(err)({
          message: 'apiアクセスでエラー発生しています。詳細を確認して対処して下さい。',
          data: params.data,
        });
      }
    },
    /**
     * RedisQueue関連
     * 1.redisのList機能を使って、Queue機能を実装する。
     * 2.上限値を設定し、上限を超えた場合、古い分を抹消
     * piao 2022-1012
     */
    async postRedisQueue(params) {
      const { data, cb, method } = params;
      try {
        /**
         * listkey形式：
         *  種別:user_id
         * data:{
         *  key:
         *  limit:queneの上限
         *  value:
         *　}
         * コンテンツは自由、但し以下は必須{
         *  uuid:
         *  createdAt:
         *  createdBy:
         * }
         */
        let res;
        switch (method) {
          case 'push':
            res = await authAxios.post(
              '/queue_push',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/queue_gets',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/queue_delete',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        console.log('useApi.postRedisQueue', err);
        cb?.failed?.(JSON.stringify(err));
      }
    },
    /**
     * Comment関連+CommentStyle
     * 1.[comment]:category:group:YYMMDDHHMMSS:id形式でredisで管理
     * 　timestampはLastXXXのみ残したい場合、
     * 2.削除はcomment:category:group:*:idで行う。
     * piao 2022-1120
     * EventもCommentStyleで処理する。2022-11-25
     * document/pageもCommentStyleで処理する。2022-12-06
     */
    async postComments(params) {
      const { data, cb, method } = params;
      try {
        // dataとして渡す為。...data->data
        let res;
        switch (method) {
          // type,category,group,id指定であれば入れ替え、なければ追加。更新日付をセットする為。2022-1221
          case 'issue':
            res = await authAxios.post(
              '/issue_comment',
              qs.stringify({
                ...data,
              }),
            );
            break;
          // type、category,group必須で、指定した情報で削除＝＞追加 2022-1212
          case 'replace':
            res = await authAxios.post(
              '/replace_comment',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'merge':
            res = await authAxios.post(
              '/merge_comment',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'delete':
            res = await authAxios.post(
              '/delete_comment',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'delete_group':
            res = await authAxios.post(
              '/delete_group',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'gets':
            res = await authAxios.post(
              '/getAll_comment',
              qs.stringify({
                ...data,
              }),
            );
            break;
          case 'get_comment':
            res = await authAxios.post(
              '/get_comment',
              qs.stringify({
                ...data,
              }),
            );
            break;
          default:
            break;
        }
        const { data: resData, message } = res.data;
        // messageに値がセットされていれば、NG
        if (_.isEmpty(message)) {
          cb?.success?.(resData);
        } else {
          cb?.failed?.(message);
        }
        return resData;
      } catch (err) {
        console.log('useApi.postComments', err);
        cb?.failed?.(JSON.stringify(err));
      }
    },
  };
}
