/**
 * notification(通知)が未許可の場合、許可を求める
 * piao 2021-03-01
 */

export default function requestNotificationPermission() {
  // Notification 許可
  if (
    window.Notification &&
    (window.Notification.permission === 'default' || window.Notification.permission === 'denied')
  ) {
    window.Notification.requestPermission();
  }
}
