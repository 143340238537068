/**
 * axiosのcatch(err)の詳細を分析して返す。
 * piao 2021-07-07
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import WinBox from 'winbox/src/js/winbox';
import 'winbox/dist/css/winbox.min.css';
import GridView from 'components/GridView';
import dayjs from 'dayjs';
/**
 * params
 * message:headerに表示されるメッセージ
 * data: 明細部のデータ
 * title:タイトル
 */

export const catchErrWinbox = (err) => (params) => {
  if (err.response) {
    // 401は権限チェックに限定する為、ここでは、無視
    // authAxios.interceptors.response.use(
    if (err.response?.status === 401) {
      return;
    }
    // responseがあるエラーは詳細を表示
    // jwtエラーを選出して対応。
    const expiredAt =
      (err.response.data?.err?.expiredAt &&
        dayjs(err.response.data?.err?.expiredAt).format(FORMAT_DAYTIME)) ||
      '';
    let jwtErrName;
    switch (err.response.data?.err?.name) {
      case 'JsonWebTokenError':
        jwtErrName = 'Jsonトークンエラー';
        break;
      case 'jwt malformed':
        jwtErrName = 'トークンの3つの部分が欠落しています。';
        break;
      case 'TokenExpiredError':
        jwtErrName = 'トークン失効エラー';
        break;
      case 'jwt expired':
        jwtErrName = 'トークンが失効しました。';
        break;
      case 'invalid token':
        jwtErrName = '無効なトークンです。';
        break;
      case 'jwt signature is required':
        jwtErrName = 'トークンは署名が必要です。';
        break;
      case 'invalid signature':
        jwtErrName = 'トークンの署名が無効です。';
        break;
    }
    let jwtErrMessage;
    switch (err.response.data?.err?.message) {
      case 'JsonWebTokenError':
        jwtErrMessage = 'Jsonトークンエラー';
        break;
      case 'jwt malformed':
        jwtErrMessage = 'トークンの3つの部分が欠落しています。';
        break;
      case 'TokenExpiredError':
        jwtErrMessage = 'トークンが失効しています';
        break;
      case 'jwt expired':
        jwtErrMessage = 'トークンが失効しました。';
        break;
      case 'invalid token':
        jwtErrMessage = '無効なトークンです。';
        break;
      case 'jwt signature is required':
        jwtErrMessage = 'トークンは署名が必要です。';
        break;
      case 'invalid signature':
        jwtErrMessage = 'トークンの署名が無効です。';
        break;
    }
    const values = jwtErrName
      ? {
          name: jwtErrName,
          message: jwtErrMessage,
          expiredAt,
        }
      : _.get(err, ['response', 'data']);

    handleWinbox(values, params);
    return;
  }
  if (err.message) {
    // console.log('catchErrWinbox:message',err.message);
    handleWinbox({ message: err.message }, params);
    return;
  }
  if (err.request) {
    // console.log('catchErrWinbox:request',err.request);
    handleWinbox({ message: 'network error' }, params);
    return;
  }
  handleWinbox({ message: 'network error' }, params);
};

export const errWinbox = (values, params) => {
  handleWinbox(values, params);
};

const handleWinbox = (values, params) => {
  const rootComponent = () => (
    <>
      {params?.message && <div className="alert alert-danger m-2">{params?.message}</div>}
      <GridView
        data={{
          ...values,
          ...(params?.data
            ? {
                data: params.data,
              }
            : {}),
        }}
        classNames="py-1 px-2"
        fieldWidth="10rem"
      />
      <div className="panel-tag fs-sm m-2 flex flex-col">
        <p>
          対象方法が不明確なエラーの場合、dataを開いて詳細が表示される画面イメージをコピーして、サポートに連絡して下さい。
        </p>
      </div>
    </>
  );

  const node = document.getElementById('catch-errorWinbox-00');

  const win = new WinBox(params?.title || 'error details', {
    id: 'catch-errorWinbox-00',
    width: '50%',
    height: '50%',
    x: 'center',
    y: 'center',
    right: 0,
    bottom: 0,
    border: 'none',
    background: '#DE3618AA',
    class: 'z-9999',
    mount: node,
  });

  const root = createRoot(win.body);

  root.render(<>{React.createElement(rootComponent)}</>);
};

// errを分析して{errcode,errmsg}で返す
// piao 202-0606
export const analizeError = (err) => {
  let errcode = 201,
    errmsg = '';
  if (err.response) {
    /**
      err.response
      {data: {…}, status: 404, statusText: 'Not Found', headers: {…}, config: {…}, …}
      config: {transitional: {…}, transformRequest: Array(1), transformResponse: Array(1), timeout: 0, adapter: ƒ, …}
      data:
        data: {xf_uuid: 'bn9rn7p73d0rp62blkhie'}
        message: "Fileが見つかりません。"
        url: ""
        [[Prototype]]: Object
      headers:
        content-length: "97"
        content-type: "application/json; charset=utf-8"
        [[Prototype]]: Object
      request: XMLHttpRequest {onreadystatechange: null, readyState: 4, timeout: 0, withCredentials: false, upload: XMLHttpRequestUpload, …}
      status: 404
      statusText: "Not Found"
      [[Prototype]]: Object
     */
    errcode = err.response?.status;
    errmsg = err.response.data?.message;
    return { errcode, errmsg };
  }
  if (err.message) {
    errmsg = err.message;
  }
  return { errcode, errmsg };
};
