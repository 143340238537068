/**
 * 各種定数を定義する。
 * 1.backend/frontend共通
 * 2.no dependency
 * piao 2021-08-12
 */

export const KB = 1024;
export const MB = 1024 * KB;
export const GB = 1024 * MB;
export const TB = 1024 * GB;

export const TECH_STACK = [
  {
    category: 'frontend',
    infos: [
      {
        stack: 'react/hooks',
        rate: '90%',
        color: 'bg-primary-700',
      },
      {
        stack: 'react-router',
        rate: '90%',
        color: 'bg-primary-500',
      },
      {
        stack: 'redux(saga)',
        rate: '90%',
        color: 'bg-primary-300',
      },
      {
        stack: 'react-query',
        rate: '90%',
        color: 'bg-primary-100',
      },
      {
        stack: 'sass',
        rate: '80%',
        color: 'bg-info-700',
      },
      {
        stack: 'less',
        rate: '50%',
        color: 'bg-info-500',
      },
      {
        stack: 'lazy-load',
        rate: '80%',
        color: 'bg-info-300',
      },
      {
        stack: 'webpack',
        rate: '80%',
        color: 'bg-info-100',
      },
      {
        stack: 'video.js',
        rate: '70%',
        color: 'bg-success-700',
      },
      {
        stack: 'PWA',
        rate: '70%',
        color: 'bg-success-500',
      },
      {
        stack: 'workbox(s/w)',
        rate: '60%',
        color: 'bg-success-300',
      },
    ],
  },
  {
    category: 'backend',
    infos: [
      {
        stack: 'node.js',
        rate: '70%',
        color: 'bg-primary-700',
      },
      {
        stack: 'express',
        rate: '70%',
        color: 'bg-primary-500',
      },
      {
        stack: 'bullmq(job管理)',
        rate: '50%',
        color: 'bg-primary-300',
      },
      {
        stack: 'Nginx',
        rate: '50%',
        color: 'bg-info-700',
      },
      {
        stack: 'pm2',
        rate: '50%',
        color: 'bg-info-500',
      },
      {
        stack: 'ubuntu',
        rate: '50%',
        color: 'bg-info-300',
      },
      {
        stack: 'SSL(Let’s Encrypt)',
        rate: '50%',
        color: 'bg-success-700',
      },
      {
        stack: 'VPS',
        rate: '75%',
        color: 'bg-success-500',
      },
      {
        stack: 'nodeMailer',
        rate: '75%',
        color: 'bg-warning-700',
      },
      {
        stack: 'JWT',
        rate: '75%',
        color: 'bg-warning-500',
      },
      {
        stack: 'domain mail',
        rate: '75%',
        color: 'bg-warning-300',
      },
    ],
  },
  {
    category: 'DB',
    infos: [
      {
        stack: 'postgreSQL',
        rate: '90%',
        color: 'bg-primary-700',
      },
      {
        stack: 'redis',
        rate: '80%',
        color: 'bg-primary-500',
      },
      {
        stack: 'sequelize',
        rate: '60%',
        color: 'bg-primary-300',
      },
      {
        stack: 'oracle',
        rate: '50%',
        color: 'bg-info-700',
      },
      {
        stack: 'DB2',
        rate: '50%',
        color: 'bg-info-500',
      },
      {
        stack: 'sqlite3',
        rate: '50%',
        color: 'bg-info-300',
      },
    ],
  },
  {
    category: 'その他(使用したTool/library)',
    infos: [
      {
        stack: 'socket.io',
        rate: '80%',
        color: 'bg-primary-700',
      },
      {
        stack: 'drag&drop',
        rate: '80%',
        color: 'bg-primary-500',
      },
      {
        stack: 'web text editor',
        rate: '80%',
        color: 'bg-primary-300',
      },
      {
        stack: 'excalidraw',
        rate: '80%',
        color: 'bg-info-700',
      },
      {
        stack: 'lodash',
        rate: '80%',
        color: 'bg-info-500',
      },
      {
        stack: 'react-pdf',
        rate: '50%',
        color: 'bg-info-300',
      },
      {
        stack: 'stripe',
        rate: '50%',
        color: 'bg-success-700',
      },
      {
        stack: 'S3',
        rate: '70%',
        color: 'bg-success-500',
      },
      {
        stack: 'CDN',
        rate: '50%',
        color: 'bg-warning-700',
      },
      {
        stack: 'push通知',
        rate: '50%',
        color: 'bg-warning-500',
      },
    ],
  },
];

export const collapseHeaderDEF = {
  sm: {
    padding: '0.25rem 0.5rem',
    top: '0.25rem',
    right: '0.5rem',
  },
  md: {
    padding: '0.5rem 0.75rem',
    top: '0.5rem',
    right: '0.75rem',
  },
  wd: {
    padding: '0.625rem 0.75rem',
    top: '0.625rem',
    right: '0.75rem',
  },
  lg: {
    padding: '0.75rem 1.0rem',
    top: '0.75rem',
    right: '1.0rem',
  },
  // 50px height
  xl: {
    padding: '0.75rem 1.0rem',
    top: '0.875rem',
    right: '1rem',
  },
  xxl: {
    padding: '0.75rem 1.0rem',
    top: '1.125rem',
    right: '1rem',
  },
  corner: {
    padding: '0.25rem',
    top: '-0.625rem',
    right: '-0.5rem',
  },
};

const monthly_price = {
  guest: 0,
  regular: 1500,
  light: 7500,
  basic: 75000,
  premium: 375000,
};
const yearly_price = {
  guest: 0,
  regular: 1000,
  light: 5000,
  basic: 50000,
  premium: 250000,
};
export const PRICE_TABLE = {
  monthly: monthly_price,
  annual: yearly_price,
  increase_users: 100000,
};

// 利用プラングレード。契約変更時に使う。222-0407
export const PLAN_GRADE = {
  guest: 0,
  regular: 1,
  light: 10,
  basic: 100,
  premium: 200,
};

export const PLAN_TITLE = {
  guest: 'ゲスト',
  regular: 'レギュラー',
  light: 'ライト',
  basic: 'ベーシック',
  premium: 'プレミアム',
};

export const LIMIT = {
  // 動画Upload,秒当たり上限　20220224
  bitRate: {
    guest: 0,
    regular: 512 * KB,
    light: 512 * KB,
    basic: 512 * KB,
    premium: 512 * KB,
  },
  // ファイルUpload
  uploadFileSize: {
    ownerAuth: 1 * GB, //folderで権限付与の場合　2023-0509
    guest: 10 * MB,
    regular: 1 * GB,
    light: 4 * GB,
    basic: 4 * GB,
    premium: 4 * GB,
  },
  // 共有Upload
  shareUploadFileSize: {
    guest: 10 * MB,
    regular: 1 * GB,
    light: 4 * GB,
    basic: 4 * GB,
    premium: 4 * GB,
  },
  // 料金プラン毎のSpace
  space: {
    guest: 100 * MB,
    regular: 10 * GB,
    light: 50 * GB,
    basic: 500 * GB,
    premium: 2.5 * TB,
  },
  // 配信先上限
  distribs: {
    guest: 0,
    regular: 0,
    light: 10,
    basic: 100,
    premium: 1000,
  },
  // 1日同時視聴上限
  concurrent: {
    guest: 0,
    regular: 0,
    light: 10,
    basic: 100,
    premium: 1000,
  },
  // 管理者追加上限
  admins: {
    guest: 0,
    regular: 0,
    light: 1,
    basic: 5,
    premium: 25,
  },
  broadband: {
    guest: 500 * MB,
    regular: 50 * GB,
    light: 250 * GB,
    basic: 2.5 * TB,
    premium: 1.25 * TB * 10,
  },
  broadSizeLimit: {
    guest: 10 * MB,
    regular: 100 * MB,
    light: 500 * MB,
    basic: 500 * MB,
    premium: 500 * MB,
  },
  // 共有ブックマーク上限
  bookmarkCount: {
    guest: 0,
    regular: 20,
    light: 10,
    basic: 100,
    premium: 100,
  },
  shareFolders: {
    guest: 0,
    regular: 5,
    light: 10,
    basic: 100,
    premium: 100,
  },
  shareFoldersLinks: {
    guest: 0,
    regular: 5,
    light: 10,
    basic: 100,
    premium: 100,
  },
  boardCount: {
    guest: 0,
    regular: 20,
    light: 50,
    basic: 100,
    premium: 100,
  },
  drawCount: {
    guest: 0,
    regular: 20,
    light: 50,
    basic: 100,
    premium: 100,
  },
  shareDocCount: {
    guest: 100,
    regular: 100,
    light: 100,
    basic: 1000,
    premium: 1000,
  },
  others: {
    poster: 10 * MB,
    system: 10 * MB,
    clipimage: 1 * MB,
  },
};

/**
 * chat/messengerのSize
 */

export const LIMIT_EDITOR_SIZE = {
  element: 10 * MB,
  singlePage: 5 * MB,
  bookmark: 5 * MB,
  homepage: 5 * MB,
  comment: 1 * MB,
  share: 1 * MB,
  shareMessage: 5 * KB,
  discussion: 3 * MB,
  support: 1 * MB,
  content: 5 * KB,
  channel: 5 * KB,
  chat: 2 * KB,
  user: 5 * KB,
  default: 5 * KB,
  board: LIMIT.broadSizeLimit,
};

export const nanoidAlphabet = '1234567890abcdefghijklmnopqrstuvwxyz';
export const weeknames = {
  jp: ['日', '月', '火', '水', '木', '金', '土'],
  ch: ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
  en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
};

// 動画をUploadするTypeを限定する　2021-0409
export const VIDEO_TYPES =
  'video/mp4,video/ogg,video/ogv,video/mov,video/quicktime,video/m4v,video/webm,video/x-matroska,video/x-flv';
export const AUDIO_TYPES = 'audio/*';
export const HLS_TYPES = 'application/vnd.apple.mpegurl';
export const AUDIO_TYPES1 =
  'audio/m4a,audio/mp4a,audio/mpga,audio/mp2,audio/mp2a,audio/mp3,audio/m2a,audio/m3a,audio/wav,audio/weba,audio/aac,audio/oga,audio/spx';
export const ACCEPT_TYPES = `${VIDEO_TYPES},${AUDIO_TYPES},${HLS_TYPES}`;
// 字幕ファイル 2021-07-04
export const SUBTITLES_TYPES = 'application/x-subrip,text/vtt,*.vtt,*.srt';

export const CSV_TYPES =
  '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values';

// react-player 2021-0409
export const MATCH_URL_YOUTUBE =
  /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//;
export const MATCH_URL_SOUNDCLOUD = /(?:soundcloud\.com|snd\.sc)\/[^.]+$/;
export const MATCH_URL_VIMEO = /vimeo\.com\/.+/;
export const MATCH_URL_FACEBOOK =
  /^https?:\/\/(www\.)?facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;
export const MATCH_URL_FACEBOOK_WATCH = /^https?:\/\/fb\.watch\/.+$/;
export const MATCH_URL_STREAMABLE = /streamable\.com\/([a-z0-9]+)$/;
export const MATCH_URL_WISTIA =
  /(?:wistia\.(?:com|net)|wi\.st)\/(?:medias|embed)\/(?:iframe\/)?(.*)$/;
export const MATCH_URL_TWITCH_VIDEO = /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/;
export const MATCH_URL_TWITCH_CHANNEL = /(?:www\.|go\.)?twitch\.tv\/([a-zA-Z0-9_]+)($|\?)/;
export const MATCH_URL_DAILYMOTION =
  /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?$/;
export const MATCH_URL_MIXCLOUD = /mixcloud\.com\/([^/]+\/[^/]+)/;
export const MATCH_URL_VIDYARD = /vidyard.com\/(?:watch\/)?([a-zA-Z0-9-]+)/;
export const MATCH_URL_KALTURA =
  /^https?:\/\/[a-zA-Z]+\.kaltura.(com|org)\/p\/([0-9]+)\/sp\/([0-9]+)00\/embedIframeJs\/uiconf_id\/([0-9]+)\/partner_id\/([0-9]+)(.*)entry_id.([a-zA-Z0-9-_]+)$/;
export const AUDIO_EXTENSIONS =
  /\.(m4a|mp4a|mpga|mp2|mp2a|mp3|m2a|m3a|wav|weba|aac|oga|spx)($|\?)/i;
export const VIDEO_EXTENSIONS = /\.(mp4|og[gv]|webm|mov|m4v)($|\?)/i;
export const HLS_EXTENSIONS = /\.(m3u8)($|\?)/i;
export const DASH_EXTENSIONS = /\.(mpd)($|\?)/i;
export const FLV_EXTENSIONS = /\.(flv)($|\?)/i;

export const distribs_format = [
  'xd_email',
  'xd_type',
  'xd_group1',
  'xd_group2',
  'xd_group3',
  'xd_remarks',
];
export const commentTypes = [
  'comment',
  'event',
  'page',
  'history',
  'link',
  'bookmark',
  'clip',
  'personal',
  'others',
  'addinfo',
];
