/**
 * zustandを使った状態管理
 * １．メイン
 * piao 2022-1101
 */
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { produce } from 'immer';
import mixin from 'mixin-deep';
import { AES, enc } from 'crypto-js';

/**
 * zustandを利用して、user状態を管理する
 * 1．user
 */
const useUsers = create(
  persist(
    (set) => ({
      user: {},
      // userを入れ替え
      resetUser: (_user) =>
        set(
          produce((state) => {
            state.user = _user;
          }),
        ),
      // 既存userの一部を入れ替え
      setUser: (_user) =>
        set(
          produce((state) => {
            mixin(state.user, _user);
          }),
        ),
      // userをクリア
      clearUser: (path = []) =>
        set(
          produce((state) => {
            _.unset(state, ['user', ...path]);
          }),
          true,
        ),
      logout: (path = []) =>
        set(
          produce((state) => {
            _.unset(state, ['user', ...path]);
          }),
          true,
        ),
    }),
    {
      name: 'su-user', // unique name
      storage: {
        getItem: (name) => {
          const str = sessionStorage.getItem(name);
          return JSON.parse(AES.decrypt(str, 'shareup.jp').toString(enc.Utf8));
        },
        setItem: (name, newValue) => {
          const str = AES.encrypt(JSON.stringify(newValue), 'shareup.jp').toString();
          sessionStorage.setItem(name, str);
        },
        removeItem: (name) => sessionStorage.removeItem(name),
      },
      // storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
      // serialize: (state) => AES.encrypt(JSON.stringify(state), 'shareup.jp').toString(),
      // deserialize: (str) => JSON.parse(AES.decrypt(str, 'shareup.jp').toString(enc.Utf8)),
    },
  ),
);
/**
 * zustandを利用して、status状態を管理する
 * 1．好きなようにデータを保存
 * piao 2022-1005
 */
const useStatus = create((set) => ({
  status: {},
  // statusを入れ替え
  resetStatus: (_status) =>
    set(
      produce((state) => {
        state.status = _status;
      }),
    ),
  // 既存statusの一部を入れ替え
  setStatus: (_status) =>
    set(
      produce((state) => {
        mixin(state.status, _status);
      }),
    ),
  // statusをクリア
  clearStatus: (path = []) =>
    set(
      produce((state) => {
        _.unset(state, ['status', ...path]);
      }),
      true,
    ),
}));
/**
 * zustandを利用して、メインplayer状態を管理する
 * 1．プレー中のVideoの全て情報を管理。状態同期
 * ２．
 * piao 2022-111
 */
const usePlayer = create((set) => ({
  zplayer: {},
  // playerを入れ替え
  resetPlayer: (_player) =>
    set(
      produce((state) => {
        state.zplayer = _player;
      }),
    ),
  // 既存playerの一部を入れ替え
  setPlayer: (_player) =>
    set(
      produce((state) => {
        mixin(state.zplayer, _player);
      }),
    ),
  // playerをクリア,path指定あれば、pathのみ
  clearPlayer: (path = []) =>
    set(
      produce((state) => {
        _.unset(state, ['zplayer', ...path]);
      }),
      true,
    ),
}));
/**
 * zustandを利用して、SocketClient状態を管理する
 * piao 2023-0218
 */
const useSocket = create((set) => ({
  socket: {},
  // playerを入れ替え
  resetSocket: (_socket) =>
    set(
      produce((state) => {
        state.socket = _socket;
      }),
    ),
  // 既存playerの一部を入れ替え
  setSocket: (_socket) =>
    set(
      produce((state) => {
        mixin(state.socket, _socket);
      }),
    ),
  // playerをクリア,path指定あれば、pathのみ
  clearSocket: (path = []) =>
    set(
      produce((state) => {
        _.unset(state, ['socket', ...path]);
      }),
      true,
    ),
}));

export { useUsers, useStatus, usePlayer, useSocket };
